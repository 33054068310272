import React from 'react'
import SectionWorkshop from './SectionWorkshop'
import StartSmall from './StartSmall'
import WorkshopLanding from './WorkshopLanding'
import Meeting from './Meeting'
import Review from './Review'
import PodcastPromotion from './PodcastSection'
import PodcastSection from './PodcastSection'
import Header from './Header'
import WatchTraining from './WatchTraining'
import Hero from './Hero'
import Footer from './Footer'

const Home = () => {
  return (
    <div className='w-full overflow-hidden'>
       
        <Hero/>
        <WatchTraining/>
        <SectionWorkshop/>
        <StartSmall/>
        <WorkshopLanding/>
      
        {/* <Review/> */}
        <PodcastSection/>
    </div>
  )
}

export default Home