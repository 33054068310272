import React from 'react'

const Review = () => {
  return (
    <div className='bg-orange-300 w-full min-h-screen'>

    </div>
  )
}

export default Review