import React from 'react'
import Img1 from '../assets/phoneusing.png'
import Meeting from './Meeting'


const WatchTraining = () => {
  return (
    <div className='w-full lg:gap-20 gap-5 relative lg:mt-48 lg:mb-20 mb-6 sm:mb-10 flex justify-center items-center  lg:flex-row flex-col  lg:px-48 '>
      
            <div className='lg:w-4/12 sm:w-6/12 w-full lg:h-[650px] lg:px-0 lg:py-0  pt-4 px-4 flex  justify-center '>
                <img src={Img1} className='h-full w-full' alt='profile banner'></img>
            </div>
            <div className='flex lg:w-5/12 sm:w-7/12 w-full px-6 justify-center items-center flex-col'>
            <span className=" font-medium text-center  Playfair lg:text-4xl text-3xl  ">
            Your business impact is only as strong as your reach.
          </span>
          <span className="lg:text-6xl sm:text-5xl text-4xl lg:pt-14 sm:pt-10 pt-6 lg:py-10 sm:py-8 py-6 text-center text-gray-600 font-extralight font-dancing  ">
          Makes sense, right?
           
          </span>
          <span className="font-medium text-center  Playfair lg:text-4xl text-3xl  w-12/12 ">
          To lead in today's digital landscape, you need a secure, scalable strategy that connects with a global audience. 
          </span>
          
            </div>
    </div>
  )
}

export default WatchTraining