import React from 'react'
import ResearchHero from './ResearchHero'
import ResearchAwards from './ResearchAwards'

const Research = () => {
  return (
    <div className='w-full  pt-16 overflow-hidden'>
      <ResearchHero/>
      <ResearchAwards/>
    </div>
  )
}

export default Research