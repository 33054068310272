'use client'

import React, { useEffect, useState } from 'react'
import { FaCalendarAlt } from "react-icons/fa"
import Call from '../assets/Call.png'

export default function Meeting() {






  return (
    <div className="w-full flex   justify-center items-center">
      <div className="bg-[#bcbfed] overflow-visible w-full lg:w-8/12 py-8 px-4 md:px-8 lg:px-16 relative ">
        <div className="lg:max-w-5xl w-full relative overflow-visible mx-auto flex flex-col md:flex-row items-center">
          <div className="absolute lg:-left-48 -top-24 lg:top-0   md:mb-0">
            <img
              src={Call}
              alt="Email List Guide Pages"
              className="h-auto lg:max-w-[250px] max-w-[180px]"
            />
          </div>
          <div className="lg:pl-28 lg:pt-0 lg:text-left text-center pt-28 text-zinc-800">
            <h2 className="uppercase lg:text-base text-sm text-[#2f0c2f] font-semibold tracking-wider lg:mb-4 mb-3">30 min FREE CALL!</h2>
            <h1 className="sm:text-4xl text-3xl lg:text-5xl Playfair leading-tight lg:mb-6 mb-5">
              To explore how we can drive growth and scale your business. <span className="text-yellow-300"></span>
            </h1>
            <div className="flex flex-col lg:items-start items-center   gap-2 mb-0 lg:mb-6">
              <a href='https://calendly.com/dr-aishwarya-gowda/30min' target='_blank'><button 
               
              
                className={`bg-white hover:bg-zinc-100 text-gray-900 flex justify-center items-center gap-2 font-medium py-3 px-16 transition-colors duration-200 uppercase text-sm tracking-wider  `}
              >
                <FaCalendarAlt/> 
               SCHEDULE NOW
              </button></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}