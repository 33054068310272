import React from 'react'
import RevenueHero from './RevenueHero'
import AboutHero from './AboutHero'
import About2 from './About2'
import About3 from './About3'
import TestimonialCarousel from './TestimonialCarousel'
import Meeting from './Meeting'
import AboutSmall from './AboutSmall'
import Awards from './Awards'

const About = () => {
  return (
    <div className='w-full  pt-16 overflow-hidden'>
        <AboutHero/>
        <About2/>
        <About3/>
        <AboutSmall/>
        <RevenueHero/>
        <TestimonialCarousel/>
        <Awards/>

        <div className='w-full' style={{
        background: "linear-gradient(to right, rgba(188, 191, 237, 0.99), rgba(188, 191, 237, 0.99))",
      }}>
        <Meeting/>
        </div>
    </div>
  )
}

export default About