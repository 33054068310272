import Meeting from "./Meeting";
import lap from '../assets/Laptop.jpeg'
import Meeting2 from "./Meeting2";

export default function WorkshopLanding() {
    return (
      <div className="relative w-full">
       <div className=" relative lg:pt-10 sm:pt-8 pt-6 sm:pb-32 pb-20 lg:pb-48" style={{
        background: "linear-gradient(to right, rgba(188, 191, 237, 0.99), rgba(220, 210, 235, 0.99), rgba(188, 191, 237, 0.99))",
      }}>
  <div className="max-w-4xl mx-auto lg:px-4 text-center">
    <p className="text-[#4b144b] uppercase tracking-widest text-lg font-semibold mb-3 lg:mb-4">Speaking experience</p>
    <h1 className="text-4xl md:text-5xl lg:text-5xl Playfair tracking-tight text-[#2d2d2d] leading-tight sm:mb-8 mb-4 lg:mb-12">
      With over 1,000 hours <br />on stage
    </h1>
  </div>

  <div className="absolute left-1/2 transform z-20 -translate-x-1/2 sm:mt-28 mt-24 lg:mt-36 -translate-y-1/2">
    <img
      src={lap}
      alt="Workshop Preview on MacBook"
      className="lg:max-w-[600px]  sm:max-w-[400px] max-w-[300px]"
    />
    
  </div>
 
</div>

        
        <div className="bg-white mt-36 pb-96 lg:pb-72">
          <div className="lg:max-w-2xl max-w-xl flex-col justify-center items-center flex mx-auto px-3 lg:px-6 text-center">
          
            
            <span className="text-zinc-700 tracking-[0.015em] font-normal text-xl ">
            Having spoken across  <span className="font-bold text-zinc-700 tracking-[0.015em] text-xl  mb-1.5">10+ countries,</span>
            </span>
            <span className="text-zinc-700 tracking-[0.015em] font-normal text-xl ">
              I'm passionate about empowering audiences to,
              <span className="mx-1 text-zinc-700 tracking-[0.015em] font-normal text-xl  mb-1.5"></span>
               harness the power of AI,<span className="font-bold text-zinc-700 tracking-[0.015em]  text-xl  mb-1.5">  </span> digital transformation, 
              <span className="mx-1 text-zinc-700 tracking-[0.015em]  text-xl  mb-1.5"></span>
              and marketing innovation   <span className="text-zinc-700 tracking-[0.015em] font-normal text-xl  ">
              <span className="font-bold">to drive growth and success.</span>  
              <span className="mx-1"></span>
            </span>
            </span>
           
            
            <a href='https://calendly.com/dr-aishwarya-gowda/30min' className="w-full" target="_blank"><button className="bg-[#bcbfed] lg:mt-8 sm:mt-6 mt-4 hover:bg-[#b0b4ed] text-zinc-900 lg:w-6/12 w-9/12 font-medium px-6 py-3 transition-colors duration-200 uppercase text-base tracking-widest">
            SCHEDULE A TIME NOW
              </button></a>
          </div>
        </div>
        <div className="absolute -bottom-24 w-full">
        <Meeting2/>
        </div>
      </div>
    )
  }
