 import React from 'react'
import ThirdHero from './ThirdHero'
import TestCollage from './TestCollage'
import ThirdList from './ThirdList'
import ThirdListSmall from './ThirdListSmall'
 
 const ThirdPage = () => {
   return (
     <div className='w-full overflow-hidden'> 
     <ThirdHero/>
     <TestCollage/>
     <ThirdList/>
     <ThirdListSmall/>

     </div>
   )
 }
 
 export default ThirdPage