import './App.css';
import Home from './components/Home';
import Footer from './components/Footer';
import About from './components/About';
import { Route, Routes } from 'react-router-dom';
import Contact from './components/Contact';
import Header from './components/Header';
import ThirdPage from './components/ThirdPage';
import ScrollToTop from './components/ScrollToTop';
import Research from './components/Research';
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <div className="App overflow-hidden">
      <ScrollToTop />




      <Header />
      <Routes>

        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/chronicles" element={<ThirdPage />} />
        <Route path="/research" element={<Research />} />

        <Route path="/contact" element={<Contact />} />

      </Routes>
      <Footer />
    </div>
  );
}

export default App;
