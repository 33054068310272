import React, { useState, useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { FiSearch } from 'react-icons/fi'
import IEEE from '../assets/Awards/ieee (1).png'
import SM from '../assets/Awards/s&m.jpg'
import AITA from '../assets/Awards/aita-logo-6.png'
import IRAJ from '../assets/Awards/iraj.jpg'
import Log from '../assets/Awards/nnfu.png'
import Crack from '../assets/Awards/i.png'
import alcoholdetec from '../assets/Pub/Alcohol Detector.jpg'
import densecrops from '../assets/Pub/dense crops.png'
import fullfield from '../assets/Pub/Full Field Non-Contact SHM.png'
import iotcnn from '../assets/Pub/IoT and CNN-Based.jpeg'
import medicalIns from '../assets/Pub/medical instru.png'
import publicsafty from '../assets/Pub/Public Safety.jpg'
import remsen from '../assets/Pub/remote sen.png'
import steeldef from '../assets/Pub/steel defect.png'
import weed from '../assets/Pub/weed.png'
import underwater from '../assets/Pub/Underwater Structural.png'
import logis from '../assets/Pub/logistics.png'
import belt from '../assets/Pub/seat.avif'






// Demo data
const tools = [
    {
        id: 4,
        name: 'Monitoring Panic Situations in Students and Alerting Using Artificial Intelligence',
        title: 'Monitoring Panic Situations',
        category: 'PUBLICATIONS',
        description: '',
        logo: SM,
        buttonText: 'Show publication',
        link: 'https://sensors.myu-group.co.jp/article.php?ss=3927'
      },
    {
        id: 1,
        name: 'Personalized E-commerce: Enhancing Customer Experience through Machine Learning-driven Personalization',
        title: 'Personalized E-commerce',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/10624901'
      },
      {
        id: 2,
        name: 'Unleashing Potential of Employees through Artificial Intelligence',
        title: 'Unleashing Potential of Employees',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/10218636'
      },
      {
        id: 3,
        name: 'Efficient Hiring Analysis and Management Using Artificial Intelligence and Blockchain',
        title: 'Efficient Hiring Analysis',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/10042815'
      },
      
      {
        id: 5,
        name: 'Precise Detection and Identification of Purslane Weeds and Crops Using YOLO V4',
        title: 'Precise Detection and Identification',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: '',
        link: ''
      },
      {
        id: 6,
        name: 'Blockchain Based Healthcare System for Elderly Care',
        title: 'Blockchain Based Healthcare System',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/9945042'
      },
      {
        id: 7,
        name: 'Blind Spot Detection for Vehicles Using YOLO',
        title: 'Blind Spot Detection for Vehicles',
        category: 'PUBLICATIONS',
        description: '',
        logo: AITA,
        buttonText: '',
        link: ''
      },
      {
        id: 8,
        name: 'Monitoring and Alerting Panic Situations in Students Using Artificial Intelligence',
        title: 'Monitoring and Alerting Panic Situations',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/9829506'
      },
      {
        id: 9,
        name: 'Medical Instruments Whereabouts Using Artificial Intelligence',
        title: 'Medical Instruments Whereabouts',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/9752235'
      },
      {
        id: 10,
        name: 'A Triple Band Microstrip Patch Antenna for S – Band & C – Band Applications',
        title: 'Triple Band Microstrip Patch Antenna',
        category: 'PUBLICATIONS',
        description: '',
        logo: IEEE,
        buttonText: 'Show publication',
        link: 'https://ieeexplore.ieee.org/document/9751980'
      }
   ,{
    id: 11,
    name: 'System Integration of Remote Sensing and Industrial Control with IoT Technologies for Water Purification Plant',
    title: 'System Integration of Remote Sensing',
    category: 'PUBLICATIONS',
    description: '',
    logo: IEEE,
    buttonText: 'Show publication',
    link: 'https://ieeexplore.ieee.org/document/9645721'
  },
  {
    id: 12,
    name: 'Logistics Monitoring and Tracking Using Artificial Intelligence and Blockchain',
    title: 'Logistics Monitoring and Tracking',
    category: 'PUBLICATIONS',
    description: '',
    logo: Log,
    buttonText: '',
    link: ''
  },
  {
    id: 13,
    name: 'Speed control of automobiles using seat belt',
    title: 'Speed Control of Automobiles',
    category: 'PUBLICATIONS',
    description: '',
    logo: IRAJ,
    buttonText: 'Show publication',
    link: 'https://engineeringjournals.stmjournals.in/index.php/JoEDT/article/view/3759'
  },
  {
    id: 14,
    name: 'Under Water Crack Detection using Image Processing (ROV)',
    title: 'Under Water Crack Detection',
    category: 'PUBLICATIONS',
    description: '',
    logo: Crack,
    buttonText: 'Show publication',
    link: 'https://www.ijraset.com/past-issue.php'
  },
    {
        id: 15,
        name: 'Identification and Removal of Denser Crops in Agricultural Farm',
        title: 'Enhancing Agricultural Yield and Resource Efficiency',
        category: 'PROJECTS',
        description: `Developed an AI-based system to identify and selectively remove overcrowded crops, enhancing agricultural yield and resource efficiency.`,
        logo: densecrops,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 16,
        name: 'Removal of Weeds & Crops Using Artificial Intelligence',
        title: 'Promoting Sustainable Farming Practices',
        category: 'PROJECTS',
        description: `Designed an AI solution to detect and remove weeds from crops, minimizing manual effort and promoting sustainable farming practices.`,
        logo: weed,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 17,
        name: 'System Integration of Remote Sensing and Industrial Control with IoT Technologies for Water Purification Plant',
        title: 'Optimizing Water Purification Processes',
        category: 'PROJECTS',
        description: `Integrated IoT-enabled remote sensing and industrial control systems to optimize operations and monitoring in water purification plants.`,
        logo: remsen,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 18,
        name: 'Medical Instrument Whereabouts Using Artificial Intelligence',
        title: 'Efficient Logistics in Healthcare Facilities',
        category: 'PROJECTS',
        description: `Developed an AI-based system to monitor and track medical instruments, ensuring efficient logistics and real-time visibility in healthcare facilities.`,
        logo: medicalIns,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 19,
        name: 'Monitoring & Tracking Logistics Using AI & Blockchain',
        title: 'Transparent and Secure Logistics Solutions',
        category: 'PROJECTS',
        description: `Implemented AI and blockchain technology to streamline logistics processes, enhance security, and provide transparent tracking solutions.`,
        logo: logis,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 20,
        name: 'Real-time Steel Defect Detection Using Neural Networks',
        title: 'Improving Quality Control in Manufacturing',
        category: 'PROJECTS',
        description: `Built a neural network-based model for real-time detection of defects in steel products, improving quality control in manufacturing.`,
        logo: steeldef,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 21,
        name: 'Full Field Non-Contact SHM Protocols for Long-Span Railway Bridges and Heritage Structures',
        title: 'INDO-CANADA Project',
        category: 'PROJECTS',
        description: `Developed structural health monitoring (SHM) protocols using non-contact methods to ensure the safety and preservation of bridges and heritage structures.`,
        logo: fullfield,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 22,
        name: 'Speed Control Using Seat Belt',
        title: 'Enhancing Automotive Safety',
        category: 'PROJECTS',
        description: `Collaborated on an innovative project to design a speed control mechanism integrated with seat belt sensors, enhancing automotive safety.`,
        logo: belt,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 23,
        name: 'Underwater Structural & Biological Health Monitoring Using ROUV',
        title: 'Ensuring Underwater Environmental Integrity',
        category: 'PROJECTS',
        description: `Utilized remotely operated underwater vehicles (ROUVs) for monitoring the structural integrity and biological health of underwater environments.`,
        logo: underwater,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    {
        id: 24,
        name: 'Alcohol Detector',
        title: 'Improving Road Safety',
        category: 'PROJECTS',
        description: `Engineered a reliable alcohol detection device to improve road safety by identifying intoxicated drivers.`,
        logo: alcoholdetec,
        buttonText: '',
        link: 'https://example.com/project-details'
    },
    
    
   
  
  ]
  

const categories = ['PUBLICATIONS', 'PROJECTS']

export default function ResearchAwards() {
  const [activeCategory, setActiveCategory] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredTools, setFilteredTools] = useState(tools)

  useEffect(() => {
    let filtered = tools

    if (activeCategory) {
      filtered = filtered.filter(tool => tool.category === activeCategory)
    }



    if (searchQuery) {
      filtered = filtered.filter(tool => 
        tool.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tool.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    setFilteredTools(filtered)
  }, [activeCategory, searchQuery])

  return (
    <div className="max-w-6xl mx-auto px-4 py-8 lg:py-12 font-sans">
    <h1 className="text-center font-pop uppercase lg:text-xl text-lg font-semibold text-gray-700 sm:mb-10 mb-8 lg:mb-12">
    Professional Milestones      </h1>

    <div className="flex flex-wrap gap-4 justify-between items-center mb-6 lg:mb-8">
      <div className="flex  flex-wrap gap-4">
          {/* Category Filters */}
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setActiveCategory(activeCategory === category ? '' : category)}
              className={`px-6 py-2 rounded-full uppercase flex items-center gap-2 ${
                activeCategory === category
                ? 'bg-purple-500/90 text-zinc-50' 
                : 'bg-purple-100/80 text-gray-700 hover:bg-purple-200'
              }`}
            >
              {category}
              {activeCategory === category && (
                <IoMdClose className="w-4 h-4" />
              )}
            </button>
          ))}

          {/* Stage Filters */}
      

        
        </div>

        {/* Search */}
        <div className="relative w-4/12 flex-grow md:flex-grow-0">
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search Tools"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full  pl-10 pr-4 py-2 rounded-full bg-purple-100 focus:outline-none focus:ring-2 focus:ring-zinc-800"
          />
        </div>
      </div>

      {/* Tools Grid */}
      <div className="space-y-8 py-10">
        {filteredTools.map(tool => (
          <div key={tool.id} className="border-b pb-8">
            <div className="flex flex-col md:flex-row w-full items-start gap-8">
              <img
                src={tool.logo}
                alt={tool.title}
                className="w-36 h-36 object-contain"
              />
              <div className="flex-1 flex flex-col">
                <div className="text-base  font-medium mb-2">
                  <span className='text-zinc-800 pop font-medium tracking-wider px-6 py-1.5 bg-purple-100'>{tool.category}</span>
                </div>
                <h2 className="text-3xl Playfair font-medium mb-4">{tool.name}</h2>
                <p className="text-gray-600 pop text-sm mb-4 flex-grow">
                  {tool.description}
                </p>
                
              </div>
              <div className="flex justify-center  md:w-3/12 lg:w-3/12  w-full items-center h-full">
              {tool.buttonText !== '' &&   <a href={tool?.link} target='_blank'> <button className="bg-purple-400/40 text-sm tracking-wide hover:bg-purple-500/50 text-black px-8 py-3  font-medium">
                    {tool.buttonText}
                  </button></a>}
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}