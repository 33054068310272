import React, { useState, useEffect } from 'react'
import Aish from '../assets/Picture1.jpg'
import Aish2 from '../assets/Picture2.jpg'
import Aish3 from '../assets/Picture3.jpg'
import Graphic1 from '../assets/newcomma.png'


const testimonials = [
  {
    id: 1,
    name: "Dr. Aishwarya Gowda",
    image: Aish,
    quote: "Innovation is more than technology; it’s about fostering trust and turning possibilities into impactful solutions.",
    bgColor: "bg-[#F8E8F0]"
  },
  {
    id: 2,
    name: "Dr. Aishwarya Gowda",
    image: Aish2,
    quote: "Scaling globally begins with understanding local markets, leveraging data, and crafting strategies that resonate universally.",
    bgColor: "bg-[#EBDCF8]"
  },
  {
    id: 3,
    name: "Dr. Aishwarya Gowda",
    image: Aish3,
    quote: "Every challenge is an opportunity to lead with innovation, inspire teams, and drive meaningful impact across industries.",
    bgColor: "bg-[#E2D5EB]"
  }
]

export default function TestCollage() {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length)
    }, 5000) // Change slide every 5 seconds

    return () => clearInterval(timer)
  }, [])

  return (
    <div className={` ${testimonials[currentIndex].bgColor} transition-colors duration-500 flex items-center justify-center pb-2 pt-6`}>
      <div className="max-w-6xl mx-auto relative">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 items-center">
          {/* Left side with collage */}
         {/* Left side with collage */}
{/* Left side with collage */}


{/* Left side with collage */}
<div className="relative h-[500px] hidden lg:flex  justify-center items-center w-[500px] mx-auto">
  {testimonials.map((testimonial, index) => {
    const isActive = index === currentIndex;

    // Define sizes and positions for the collage layout
    const positions = [
      {
        left: '50%',
        top: '0',
        size: 'w-[180px] h-[220px] sm:w-[220px] sm:h-[260px] md:w-[280px] md:h-[320px]', // Largest image
        zIndex: isActive ? 30 : 20,
      },
      {
        left: '20%',
        top: '50%',
        size: 'w-[140px] h-[170px] sm:w-[180px] sm:h-[210px] md:w-[220px] md:h-[250px]', // Medium image
        zIndex: isActive ? 30 : 15,
      },
      {
        left: '0',
        top: '25%',
        size: 'w-[120px] h-[150px] sm:w-[150px] sm:h-[180px] md:w-[180px] md:h-[220px]', // Smallest image
        zIndex: isActive ? 30 : 10,
      },
    ];
   

    return (
      <div
      key={testimonial.id}
      className={`absolute  cursor-pointer transition-transform duration-500 ease-in-out 
        ${isActive ? 'bg-transparent' : 'bg-white/100'}`} // Non-active frames get a semi-transparent white background
      onClick={() => setCurrentIndex(index)}
      style={{
        left: positions[index].left,
        top: positions[index].top,
        zIndex: positions[index].zIndex,
        opacity: isActive ? 1 : 0.2,
      }}
    >
      <img
        src={testimonial.image}
        alt={testimonial.name}
        className={`${positions[index].size}  object-cover md:p-2 p-1 bg-white shadow-lg `}
      />
    </div>
    

    

    
    );
    
  })}

  
</div>


<div className="relative h-[350px] sm:h-[500px] md:h-[550px] lg:hidden flex   justify-center items-center md:w-10/12 w-full sm:w-9/12 mx-auto">
  {testimonials.map((testimonial, index) => {
    const isActive = index === currentIndex;

    // Define sizes and positions for the collage layout
    const positions = [
      {
        right: '10%',
        left:'',
        top: '12%',
        size: 'w-[160px] h-[200px] sm:w-[250px] sm:h-[290px] md:w-[280px] md:h-[320px]', // Largest image
        zIndex: isActive ? 30 : 20,
      },
      {
        right: '40%',
        left: '',
        top: '50%',
        size: 'w-[120px] h-[150px] sm:w-[210px] sm:h-[240px] md:w-[240px] md:h-[270px]', // Medium image
        zIndex: isActive ? 30 : 15,
      },
      {
        right: '',
        left: '10%',
        top: '30%',
        size: 'w-[100px] h-[130px] sm:w-[180px] sm:h-[210px] md:w-[200px] md:h-[240px]', // Smallest image
        zIndex: isActive ? 30 : 10,
      },
    ];
   

    return (
      <div
      key={testimonial.id}
      className={`absolute  cursor-pointer transition-transform duration-500 ease-in-out 
        ${isActive ? 'bg-transparent' : 'bg-white/100'}`} // Non-active frames get a semi-transparent white background
      onClick={() => setCurrentIndex(index)}
      style={{
        right: positions[index].right,
        left: positions[index].left,
       
        top: positions[index].top,
        zIndex: positions[index].zIndex,
        opacity: isActive ? 1 : 0.2,
      }}
    >
      <img
        src={testimonial.image}
        alt={testimonial.name}
        className={`${positions[index].size}  object-cover md:p-2 p-1 bg-white shadow-lg `}
      />
    </div>
    

    

    
    );
    
  })}

  
</div>




          {/* Right side with quote */}
          <div className='flex justify-center lg:px-0 md:px-24 sm:px-16 px-4 items-center flex-col'>
          <div className="relative bg-purple-50 lg:py-4 sm:px-10 sm:py-3 py-2 px-8 lg:px-12">
            {/* Quote mark decoration */}
            <div className="absolute -top-5 left-0 lg:w-12 sm:w-10 w-8 h-8  rounded-t-full flex items-end justify-center pb-1">
            <img src={Graphic1} alt='graphic'></img>
            </div>
            <div className="absolute -bottom-5 right-0 lg:w-12 sm:w-10 w-8 h-8 rotate-180  rounded-t-full flex items-end justify-center pb-1">
            <img src={Graphic1} alt='graphic2'></img>
            </div>

            {/* Testimonial content */}
            <div className="lg:space-y-6 space-y-4">
              <div className="transition-opacity text-center duration-500">
                <p className="lg:text-2xl text-xl Playfair leading-relaxed text-gray-800">
                  {testimonials[currentIndex].quote}
                </p>
              </div>
              
             

            </div>
            </div>
            <div className="lg:mt-6 mt-2 sm:mt-4">
                <p className="lg:text-5xl sm:text-3xl text-2xl font-medium text-center font-dancing text-gray-800">
                  {testimonials[currentIndex].name}
                </p>
              
              </div>
          </div>
        </div>
      </div>
    </div>
  )
}