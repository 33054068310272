import React, { useState } from 'react';
import about from '../assets/About me Banner.png';
import emailjs from 'emailjs-com';
import { toast, ToastContainer } from 'react-toastify';  // Importing Toastify
import 'react-toastify/dist/ReactToastify.css';  // Importing Toastify styles

const Contact = () => {
  const [formData, setFormData] = useState({
    subject: '',
    email: '',
    message: ''
  });

  // Function to validate form fields
  const validateForm = () => {
    if (formData.subject.length < 4) {
      toast.error("Subject too short.");
      return false;
    }
    
    if (formData.message.length < 10) {
      toast.error("Message too short.");
      return false;
    }
    
    
    

    if (!formData.subject || !formData.email || !formData.message) {
      toast.error("All fields are required!");
      return false;
    }
    // Simple email format validation
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;

    if (!emailPattern.test(formData.email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }
    
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form data before sending the email
    if (!validateForm()) return;

    const form = e.target;

    // Send the email using EmailJS
    emailjs.sendForm('service_yh5jz57', 'template_hwrokym', form, 'Q_Zr0SvF8iFj9_ovF')
      .then((response) => {
        // console.log('Email successfully sent:', response);
        toast.success("Message sent successfully!");

        // Clear form fields after successful submission
        setFormData({ subject: '', email: '', message: '' });
      }, (error) => {
        // console.error('Failed to send email:', error);
        toast.error("Failed to send message.");

        // Optionally, you could clear the form on error, or leave it as is for user correction
        setFormData({ ...formData });  // You can also keep the form data if you prefer
      });
  };

  return (
    <div className="w-full relative flex justify-start px-4 sm:px-8 md:px-16 lg:px-32 xl:px-64 items-center mt-16 h-screen md:min-h-screen overflow-hidden">
      {/* Background Image */}
      <div className="absolute -z-10 md:top-3 top-0 left-0 w-full h-full">
        <img
          src={about}
          className="h-full w-full object-cover"
          alt="Background"
        />
      </div>

      {/* Content */}
      <div className="w-full max-w-lg p-2 sm:p-6">
        <div className='flex justify-start w-full items-start flex-col'>
          <span className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl w-full text-left font-thin font-dancing">
            Let's talk!
          </span>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-4 sm:space-y-6 mt-3 sm:mt-5">
          <div>
            <label htmlFor="subject" className="block text-gray-900 mb-2">
              Subject
            </label>
            <input
              type="text"
              id="subject"
              name="subject"
              value={formData.subject}
              onChange={(e) => setFormData({ ...formData, subject: e.target.value })}
              className="w-full px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-purple-50 border-transparent focus:border-purple-500 focus:bg-white focus:ring-0 text-sm sm:text-base"
              required
            />
          </div>

          <div>
            <label htmlFor="email" className="block text-gray-900 mb-2">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              className="w-full px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-purple-50 border-transparent focus:border-purple-500 focus:bg-white focus:ring-0 text-sm sm:text-base"
              required
            />
          </div>

          <div>
            <label htmlFor="message" className="block text-gray-900 mb-2">
              Message
            </label>
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={(e) => setFormData({ ...formData, message: e.target.value })}
              className="w-full px-3 sm:px-4 py-2 sm:py-3 rounded-lg bg-purple-50 border-transparent focus:border-purple-500 focus:bg-white focus:ring-0 h-32 resize-none text-sm sm:text-base"
              placeholder="Type something if you want..."
              required
            ></textarea>
          </div>

          <button
            type="submit"
            className="w-full bg-purple-600/70 text-white py-2 sm:py-3 uppercase tracking-wider hover:bg-purple-700/40 transition-colors duration-200 text-sm sm:text-base"
          >
            Send Message
          </button>
        </form>
      </div>

      <ToastContainer 
  position="top-center"   // Positioning toast in the top center
  autoClose={5000}        // Optional: automatically close after 5 seconds
  hideProgressBar={false}  // Optional: hide progress bar
  newestOnTop={true}      // Optional: new toasts on top
  closeButton={false}     // Optional: hide close button
/>
    </div>
  );
};

export default Contact;
