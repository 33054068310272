import { FaArrowUp, FaFacebookSquare, FaInstagramSquare, FaWhatsappSquare } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Logo from '../assets/Footer logo.png'


function Footer() {
  return (
    <footer className="w-full px-4 py-8 sm:py-12 md:px-8 lg:px-16 xl:px-36" style={{
      background: "linear-gradient(to right, rgba(188, 191, 237, 0.79), rgba(188, 191, 237, 0.79))",
    }}>
      <div className="flex flex-col md:flex-row justify-between items-start w-full">
        <div className="flex flex-col w-full md:w-6/12 justify-start gap-4 items-start mb-8 md:mb-0">
          <div>
            <Link to={'/'}>
              <img src={Logo} className='w-16 sm:w-20' alt="Logo" />
            </Link>
          </div>
          <div>
            <p className="text-zinc-700 w-full md:w-10/12 lg:w-8/12 tracking-[0.015em] font-normal text-sm sm:text-base">
              I'm a tech enthusiast, global marketing strategist, and innovation advocate, with a passion for scaling businesses and pioneering AI-driven solutions. As an international speaker on emerging technology. I'm here to share insights on transforming ideas into impactful innovations. 
            </p>
            <p className="text-zinc-700 w-full pt-2 md:w-10/12 lg:w-8/12 tracking-[0.015em] font-normal text-sm sm:text-base">
            Welcome to my digital space-stay a while, explore, and let's reimagine the future of technology together!
            </p>
           
          </div>
        </div>
        
        <div className="flex flex-col gap-6 w-full md:w-3/12 justify-start items-start mb-8 md:mb-0">
          <div className="flex justify-start items-start flex-col gap-4">
            <Link to={'/'}><span className="text-sm sm:text-base hover:text-purple-600 transition-all font-medium tracking-wide cursor-pointer">HOME</span></Link>
            <Link to={'/about'}><span className="text-sm sm:text-base  hover:text-purple-600 transition-all font-medium tracking-wide cursor-pointer">ABOUT</span></Link>
            <Link to={'/chronicles'}><span className="text-sm sm:text-base  hover:text-purple-600 transition-all font-medium tracking-wide cursor-pointer">CHRONICLES</span></Link>
            <Link to={'/research'}><span className="text-sm sm:text-base  hover:text-purple-600 transition-all font-medium tracking-wide cursor-pointer">RESEARCH</span></Link>
            <Link to={'/contact'}><span className="text-sm sm:text-base  hover:text-purple-600 transition-all font-medium tracking-wide cursor-pointer">CONTACT</span></Link>
          </div>
        </div>
        
        <div className="flex justify-start w-full md:w-3/12 items-start md:items-end flex-col gap-6">
          <span className="text-lg sm:text-xl font-semibold tracking-wide">LET'S BE FRIENDS</span>
          <div className="flex flex-row justify-start md:justify-end items-center gap-5">
            <a href='https://www.linkedin.com/in/dr-aishwarya-gowda/' target='_blank' rel="noopener noreferrer">
              <FaLinkedin className="text-2xl sm:text-3xl cursor-pointer"/>
            </a>
            <div className="flex justify-start items-start flex-row gap-5 sm:gap-8">
              <a href='https://www.instagram.com/dr.aishwarya.gowda/' target='_blank' rel="noopener noreferrer">
                <FaInstagramSquare className="text-2xl sm:text-3xl cursor-pointer"/>
              </a>
              <a href="https://wa.me/qr/2EVNCVPHF2MKI1" target='_blank' rel="noopener noreferrer">
                <FaWhatsappSquare className="text-2xl sm:text-3xl cursor-pointer"/>
              </a>
              <a href='https://www.facebook.com/profile.php?id=100013426633763' target='_blank' rel="noopener noreferrer">
                <FaFacebookSquare className="text-2xl sm:text-3xl cursor-pointer"/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )}

export default Footer;
