import PhoneImg from '../assets/PhoneImg.png'
import arrowh from '../assets/arrowh.svg'


export default function PodcastSection() {
  return (
    <div className="lg:pt-48 pt-40 pb-20 px-4 md:px-6" style={{
      background: "linear-gradient(to right, rgba(248, 235, 248, 0.99), rgba(220, 210, 235, 0.99), rgba(188, 191, 237, 0.99))",
    }}>
      <div className="max-w-6xl mx-auto flex flex-col md:gap-0  gap-10  md:flex-row items-center">
        {/* Text Section */}
        <div className="w-full md:w-7/12 flex flex-col justify-center items-center md:items-start space-y-5 text-center md:text-left mb-8 md:mb-0">
          <h1 className="text-3xl sm:text-4xl md:text-5xl w-full md:w-11/12 md:pl-12 font-serif text-[#333]">
            Transformative Thinking in Tech and Global Marketing
          </h1>
          <div className="flex flex-col md:pl-16 pt-2 md:-rotate-3 gap-0 text-zinc-700">
            <span className="text-3xl sm:text-4xl md:text-5xl font-thin font-dancing">
              Transforming <span className="font-dancing text-[#a25ad5]">Ideas</span> into <span className="text-[#a25ad5] font-dancing">Innovation</span>,
            </span>
            <span className="text-3xl sm:text-4xl md:text-5xl md:pl-36 font-extralight font-dancing">
              One Insight at a Time.
            </span>
            <div className="w-full hidden md:flex justify-end items-end">
              <img src={arrowh} width={48} height={48} alt="Decorative arrow" className="w-12 -rotate-180" />
            </div>
          </div>
          <p className="text-zinc-700 w-full md:w-11/12 tracking-[0.015em] font-normal text-base sm:text-lg md:pl-12">
            From accelerating the global reach of tech brands to cultivating high-performing teams and driving innovation through AI-powered marketing strategies - my expertise spans every facet of transformative growth
          </p>
          <p className="italic text-zinc-700 tracking-[0.015em] font-normal text-base sm:text-lg md:pl-12">Nothing is off-limits.</p>

          <div className="w-full flex justify-center md:justify-start">
          <a className="w-full" href="https://www.linkedin.com/build-relation/newsletter-follow?entityUrn=7263109084239806464" target="_blank"><button className="bg-[#bcbfed] lg:ml-12 mt-2 lg:mt-8 hover:bg-[#b0b4ed] text-zinc-900 lg:w-6/12 sm:w-8/12 w-9/12 font-medium px-6 lg:py-3 py-3 transition-colors duration-200 uppercase lg:text-base text-sm tracking-widest">
          Subscribe on LinkedIn              </button></a> 
          </div>
        </div>

        {/* Image Section */}
        <div className="w-48 sm:w-3/3 lg:w-[34%] md:pr-20 flex justify-center items-center">
<img
  src={PhoneImg}
  alt="Podcast Mockup"
  className="w-full"
/>
</div>
        </div>
      </div>
    
  )
}


