import phd from '../assets/PhD.jpg'
import RH2 from '../assets/otherpic.jpg'
import RH3 from '../assets/otherpic2.jpg'

export default function RevenueHero() {
    return (
      <section className="w-full relative mb-16 md:mb-28 py-5 md:py-8">
        <div className="absolute top-0 w-full h-[650px] sm:h-[800px] md:h-[650px]" style={{
          background: "linear-gradient(to right, rgba(248, 235, 248, 0.99), rgba(220, 210, 235, 0.99), rgba(188, 191, 237, 0.99))",
        }}></div>

        <div className="container mx-auto px-4 max-w-6xl">
          <div className="flex flex-col relative md:px-0 sm:px-16  py-8 lg:py-10 md:flex-row gap-8 md:gap-12 items-start">

            {/* Left Column - Text and Small Images */}
            <div className="w-full md:w-1/2 space-y-8">
              <div className="space-y-4 pl-4 md:pl-16">
                <h2 className="text-[13px] uppercase tracking-[0.2em] text-[#6e6e6e] font-sans font-semibold">
                  HERE'S MY Secret
                </h2>
                <p className="text-3xl lg:text-5xl sm:text-4xl Playfair text-[#2f2f2f] lg:leading-[1.05]">
                  Dream big, chase it relentlessly, and you'll achieve it - just like I did,

                  <span className="relative italic mx-2 inline-block">
                    overcoming
                    <span
                      className="absolute left-0 w-full bg-[#df72d0] bg-opacity-30 z-10"
                      style={{ height: '35%', bottom: -6 }}
                    ></span>
                  </span>

                  countless obstacles and proving those who doubted me wrong.
                </p>
              </div>
              
              {/* Small Images Container */}
              <div className="relative h-72 mt-20 hidden md:block">
                <img
                  src={RH3}
                  alt="Working with dog"
                  className="absolute lg:left-16 lg:-bottom-20 left-20 -bottom-20 lg:w-64 w-40 h-48 lg:h-72 object-cover rounded-sm z-10"
                />
                <img
                  src={RH2}
                  alt="Early career"
                  className="absolute p-3 bg-white lg:right-0 lg:-top-10 right-0 top-10 lg:w-[300px] w-[200px] h-48 lg:h-72 object-cover rounded-sm"
                />
              </div>
            </div>
  
            {/* Right Column - Main Image */}
            <div className="w-full md:w-1/2 flex justify-center items-center  mt-8 md:mt-0">
              <img
                src={phd}
                alt="Marketing expert in yellow dress"
                className="lg:w-full md:w-96 w-80 rounded-sm"
              />
            </div>
          </div>
        </div>
      </section>
    )
  }