import React, { useEffect, useState } from 'react'
import Graphic1 from '../assets/newcomma.png'

const AboutSmall = () => {

  const [isActive, setIsActive] = useState(true); // State to toggle active text

  useEffect(() => {
    // Set interval to toggle the background color every 0.1 seconds
    const intervalId = setInterval(() => {
      setIsActive((prev) => !prev);
    }, 700); // 100 milliseconds = 0.1 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='w-full lg:py-16 sm:py-10 py-8 lg:px-0 px-6   relative bg-white'>
        <div className='w-full flex lg:gap-4 gap-2 flex-col  justify-center items-center'>
            <div className='text-center'> <span className='lg:text-6xl sm:text-4xl text-center w-full text-3xl Playfair italic'>Your background may shape you, </span>
            
</div>
<span className='lg:text-3xl sm:text-2xl text-xl Playfair italic'>but your </span>
<div className=''> <span
        className={`lg:text-7xl text-3xl sm:text-5xl lg:px-3 px-1.5 font-medium tracking-tight Playfair transition-all duration-200 ${
          isActive ? 'bg-[#f9def9] ' : ''
        }`}
      >
        VISION
      </span>
<span className='lg:text-5xl text-2xl sm:text-3xl Playfair px-0.5 lg:px-2'>&</span>

      {/* "HERE" span */}
      <span
        className={`lg:text-7xl text-3xl sm:text-5xl lg:px-3 px-1.5 font-medium tracking-tight Playfair transition-all duration-200 ${
          !isActive ? 'bg-[#f9def9]' : ''
        }`}
      >
         COURAGE
      </span>

</div>
<div> <span className='lg:text-4xl text-xl sm:text-2xl Playfair italic'>define how far you can go. </span>
</div>


        </div>

<div className='lg:w-28 sm:w-10 w-8  absolute md:left-28 left-2 lg:left-48 opacity-65 top-1 lg:-top-0  z-10'>
    <img src={Graphic1}></img>
</div>

<div className='lg:w-28 sm:w-10 w-10  absolute md:right-28  right-2 lg:right-48 opacity-65 bottom-1 lg:-bottom-0 rotate-180 z-10'>
    <img src={Graphic1}></img>
</div>
    </div>
  )
}

export default AboutSmall