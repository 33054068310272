import React, { useState } from 'react'
import { List1, List2 } from './List'
import Img1 from '../assets/List/1.png'
import Img2 from '../assets/List/2.png'
import Img3 from '../assets/List/3.png'
import Img4 from '../assets/List/4.png'
import Img5 from '../assets/List/5.png'
import Img6 from '../assets/List/6.png'
import Img7 from '../assets/List/7.png'
import Img8 from '../assets/List/8.png'
import Img9 from '../assets/List/9.png'
import { FaArrowDown, FaArrowTrendDown, FaArrowTrendUp, FaArrowUp } from 'react-icons/fa6'
import { FaArrowAltCircleDown } from 'react-icons/fa'
import Img10 from '../assets/List/10.png'
import Img11 from '../assets/List/11.png'
import Img12 from '../assets/List/12.png'

const ThirdListSmall = () => {
  const [visibleBlocks, setVisibleBlocks] = useState(6)

  const allData = [
    {
      title: 'Bridging Global Innovation for a Sustainable Future',
      head: 'From Taiwan to MENA: Ideas, Impact, and Collaboration',
      para1: 'At the Westin Dubai Mina Seyahi Beach Hotel, I had the privilege to speak and engage with visionaries, founders, and venture capitalists from around the world. This event was a platform for fostering meaningful dialogues about sustainability, innovation, and bridging markets across regions. It was inspiring to exchange ideas and share perspectives on creating lasting impact globally.',
      para2:`As a professional passionate about scaling brands and driving transformative growth, moments like these reaffirm the importance of connecting ideas to actions that shape the future.`,
      link:'https://www.linkedin.com/in/dr-aishwarya-gowda/',
      img: Img10
    },
    {
      title: 'Discover how I help industry leaders, enterprises, and innovators',
      head: 'Singapore Fintech Festival',
      para1: 'Speaking at the Meet Taiwan Fintech Ecosystem was a truly inspiring experience! I had the privilege of showcasing our cutting-edge video-based digital signature solution to a forward-thinking audience passionate about fintech transformation',
      para2:`The event was further elevated by the presence of the Ambassador of the Taipei Representative Office in Singapore, who honored us with a visit and engaged in a thought-provoking discussion about our product's potential impact.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_sff2024-fintechspace-meettaiwan-activity-7262075887599140865-UFO0?utm_source=share&utm_medium=member_desktop',
      img: Img1
    },
    {
      title: '  Exploring Innovation and Collaboration in Technology',
      head: 'GITEX Global & Expand North Star',
      para1: `Dubai's dynamic tech landscape provided an incredible opportunity to connect with industry leaders and dive into groundbreaking innovations shaping the future`,
      para2:'As a speaker and global sales and marketing expert, I was inspired by the energy and transformative ideas exchanged. Meaningful conversations with visionaries emphasized the vital role of collaboration in driving technological advancements',
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_gitex2024-ens-techinnovation-activity-7251893042670940160-klSY?utm_source=share&utm_medium=member_desktop',
      img: Img2
    },
    {
      title: 'Innovating with Indian Railways',
      head: 'Exploring Opportunities for the Future with IRCTC',
      para1: 'I had the privilege of engaging in a highly insightful discussion at the Center for Railway Information Systems (CRIS) in Delhi with esteemed leaders, including Chief Administrative Officer Ravinesh Kumar, Ravi Mohan Sharma, and Dr. Vibhor Sahgal.',
      para2:'With a strong passion for fostering innovation and meaningful collaborations, this meeting provided an excellent platform to exchange ideas and explore opportunities to boost efficiency and drive innovation within the railway sector.',
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_selfiesign-indianrailways-collaboration-activity-7220451779698679808-s6RJ?utm_source=share&utm_medium=member_desktop',
      img: Img3
    },
    {
      title: `Joining FinTechSpace Taiwan's Delegation to Malaysia`,
      head: 'Exploring the Malaysian Fintech Landscape',
      para1: `Thrilled to have joined FinTechSpace Taiwan's delegation to Malaysia, where I had the privilege of engaging with key players shaping the country's fintech ecosystem.`,
      para2:`From discussions with regulatory bodies like Securities Commission Malaysia and Bank Negara Malaysia to insights from innovative digital banks and organizations like MYStartup and FAOM, the experience offered a comprehensive view of Malaysia's fintech landscape and its future trajectory.`,
      para3: `Connecting with MDEC further highlighted the nation's commitment to digital transformation and fintech innovation. Grateful for the opportunity to exchange ideas and explore collaborative opportunities that can drive meaningful impact.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_exciting-news-exploring-the-malaysian-activity-7215606106335662080-nw4F?utm_source=share&utm_medium=member_desktop',
      img: Img4
    },
    {
      title: `Fostering Global Connections in Finance`,
      head: 'Exploring Collaborative Opportunities with ICICI Bank',
      para1: `I recently had the privilege of engaging with key leaders from ICICI Bank, including Amit Parikh, Head of IFIG MNC Ecosystem; Sandeep Upadrasta, Regional Head for North Asia, Pacific & MENA; and Lucy Wei, Relationship Manager, Taiwan Desk.`,
      para2:`Our discussions in Taiwan unveiled exciting collaboration opportunities, reflecting the growing synergy between innovative solutions and global financial ecosystems. Grateful for the insightful exchange of ideas and perspectives that could lead to impactful advancements.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_thinkcloudtechnology-selfiesign-digitalsignatures-activity-7207780997155753984--KPV?utm_source=share&utm_medium=member_desktop',
      img: Img5
    },
    {
      title: `Showcasing Innovation and Building Connections`,
      head: 'Highlights from Taiwan Expo 2024 in Jakarta',
      para1: `Participating in Taiwan Expo 2024 in Jakarta, Indonesia, was an inspiring experience! Delivering a live product demonstration to a dynamic and engaged audience was truly rewarding, and the event provided an exceptional platform to connect with industry leaders and explore emerging technologies.`,
      para2:`The expo was a hub of innovation and collaboration, offering valuable opportunities to exchange ideas and build meaningful partnerships. Grateful for the insightful conversations and the incredible feedback from professionals who engaged with the demonstration.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_taiwanexpo2024-jakarta-productdemonstration-activity-7198565814831112192-su4y?utm_source=share&utm_medium=member_desktop',
      img: Img6
    },
    {
      title: `Pioneering Innovation in Smart City Solutions`,
      head: 'Highlights from Taiwan Expo',
      para1: `Attending Taiwan Expo in Mumbai was an extraordinary experience! Over three dynamic days, I had the opportunity to engage with industry professionals and tech enthusiasts, sharing innovative ideas and exploring the future of smart city technologies.`,
      para2:`The event offered a platform to demonstrate advanced solutions, including concepts like secure digital transactions and identity verification. The enthusiastic response from visitors reflected the growing interest in transformative technologies and reinforced the potential for impactful advancements in the field.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_thinkcloudtech-taiwanexpo2023-innovationjourney-activity-7136374493853220866-oyg0?utm_source=share&utm_medium=member_desktop',
      img: Img7
    },
    {
      title: `Shaping the Future of Digital Transactions`,
      head: 'Highlights from the Singapore Fintech Festival',
      para1: `I had the incredible opportunity to engage with professionals across industries at this global event. Demonstrating the transformative potential of video-based digital signatures sparked meaningful discussions about secure and seamless transaction technologies.`,
      para2:`The dynamic energy of the festival and the inspiring conversations reinforced the importance of innovation in shaping the future of fintech. Grateful for the chance to connect with forward-thinking individuals and explore opportunities for impactful collaborations.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_sff2023-fintechinnovation-thinkcloudtechnology-activity-7133871804577677312-9y7i?utm_source=share&utm_medium=member_desktop',
      img: Img8
    },
    {
      title: `Driving Innovation in Financial Technology`,
      head: 'Showcasing Solutions at a Financial Seminar in Kuala Lumpur, Malaysia',
      para1: `I had the privilege of addressing a distinguished audience from leading financial institutions, including Cathay United Bank, CTBC Bank, Bangkok Bank, Mega Bank, and others. Drawing on my expertise in digital transformation and secure transaction solutions, I demonstrated the potential of advanced technologies like video-based digital signatures, FaceOTP, and iMeeting to revolutionize remote transactions by enhancing security and operational efficiency.`,
      para2:`The engaging discussions underscored my ability to connect with diverse stakeholders and present complex ideas in a way that resonates across industries. It was an enriching experience to leverage my skills in strategic communication, technology advocacy, and market insights to explore how innovation can address pressing challenges in the financial sector.`,
      link:'https://www.linkedin.com/posts/dr-aishwarya-gowda_on-thursday-october-6-2022-selfiesign-activity-6985902348732678144-3cdK?utm_source=share&utm_medium=member_desktop',
      img: Img9
    }
    ,
    {
      title: `Advancing Global Collaboration in Innovation and Research`,
      head: ' Indo-Taiwan Summit at Paavai Institutions, India',
      para1: `I was honored to be part of the Indo-Taiwan Summit for Innovation and Research, hosted by Paavai Institutions, India. Alongside esteemed Professors and Directors from Taiwan, this summit served as a platform to bridge academic and technological advancements between nations.`,
      para2:`Speaking at this event was a valuable opportunity to share insights and foster partnerships that drive impactful research and innovation. It’s always inspiring to connect with global leaders united in the vision of shaping a brighter, collaborative future.`,
      link:'https://www.linkedin.com/in/dr-aishwarya-gowda/details/featured/',
      img: Img11
    },
    {
      title: `Empowering Minds Through Digital Communication`,
      head: 'Guest Lecture at Nan Hua University, Taiwan',
      para1: `I had the honor of delivering a 6-hour lecture on Digital Communication at Nan Hua University, Taiwan. This opportunity allowed me to engage with brilliant minds, fostering an exchange of knowledge and insights about the evolving landscape of communication technologies.`,
      para2:`As someone deeply invested in advancing technology and education, sharing expertise on such platforms fuels my passion for innovation and inspiring the next generation of learners.`,
      link:'https://www.linkedin.com/in/dr-aishwarya-gowda/details/featured/',
      img: Img12
    }
  ]

  const toggleVisibility = () => {
    if (visibleBlocks === allData.length) {
      setVisibleBlocks(6)
    } else {
      setVisibleBlocks(allData.length)
    }
  }

  return (
    <div className='w-full gap-48 lg:px-0 sm:px-16 md:px-24 px-6 lg:hidden  md:py-16 pt-28  py-10 flex justify-center items-center flex-col' style={{
      background: "linear-gradient(to right, rgba(248, 235, 248, 0.99), rgba(220, 210, 235, 0.99), rgba(188, 191, 237, 0.99))",
    }}>
      {allData.slice(0, visibleBlocks).map((data, index) => (
         <List1 key={index} data={data} /> 
      ))}
      
      {allData.length > 6 && (
        <button 
          onClick={toggleVisibility}
          className="mt-8 px-8 py-3 bg-purple-600/40  text-gray-900 tracking-wider uppercase  hover:bg-purple-700/40 transition-colors gap-2 flex font-semibold font-pop justify-center items-center flex-row duration-300"
        >
          {visibleBlocks === allData.length ? "Show Less" : "Show More"}
          {visibleBlocks === allData.length ? <FaArrowUp/> : <FaArrowDown/>}

        </button>
      )}
    </div>
  )
}

export default ThirdListSmall