import React from 'react';
import styled from 'styled-components';

const AnimatedText = () => {
  return (
    <StyledWrapper>
      <div className="card ">
        <div className="loader lg:p-3 p-2 lg:h-11 sm:h-9 h-7 ">
          
          <div className="words text-zinc-900 text-center lg:text-4xl sm:text-3xl text-xl Playfair">
            <span className="word">PhD in Artificial Intelligence</span>
            <span className="word">Global Sales & Marketing Director</span>
            <span className="word">SEO & SEM Expert</span>
            <span className="word">Web3 Product Specialist</span>
            <span className="word">Speaker on Emerging Technology</span>
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  
  .loader {
    color: rgb(124, 124, 124);
    font-family: "Poppins", sans-serif;
    font-weight: 500;
  
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
   
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 8px;
  }

  .words {
    overflow: hidden;
    position: relative;
  }
  .words::after {
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      var(--bg-color) 10%,
      transparent 30%,
      transparent 70%,
      var(--bg-color) 90%
    );
    z-index: 20;
  }

  .word {
    display: block;
    height: 100%;
   
    
    animation: spin_4991 7s infinite;
  }

  @keyframes spin_4991 {
    10% {
      -webkit-transform: translateY(-102%);
      transform: translateY(-102%);
    }

    25% {
      -webkit-transform: translateY(-100%);
      transform: translateY(-100%);
    }

    35% {
      -webkit-transform: translateY(-202%);
      transform: translateY(-202%);
    }

    50% {
      -webkit-transform: translateY(-200%);
      transform: translateY(-200%);
    }

    60% {
      -webkit-transform: translateY(-302%);
      transform: translateY(-302%);
    }

    75% {
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }

    85% {
      -webkit-transform: translateY(-402%);
      transform: translateY(-402%);
    }

    100% {
      -webkit-transform: translateY(-400%);
      transform: translateY(-400%);
    }
  }`;

export default AnimatedText;
