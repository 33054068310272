import React, { useEffect, useState } from 'react'
import Graphic1 from '../assets/Graphic1.png'
import arrowd from '../assets/arrowd.png'

const StartSmall = () => {

  const [isActive, setIsActive] = useState(true); // State to toggle active text

  useEffect(() => {
    // Set interval to toggle the background color every 0.1 seconds
    const intervalId = setInterval(() => {
      setIsActive((prev) => !prev);
    }, 700); // 100 milliseconds = 0.1 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='w-full lg:pt-24 pt-16 lg:pb-6 pb-4 relative '>
        <div className='w-full flex gap-4  flex-col  justify-center items-center'>
            <div> <span className='lg:text-6xl text-3xl sm:text-4xl Playfair italic'>Start with a </span>
</div>
<div className='flex justify-center items-center lg:flex-row flex-col'> <span
        className={`text-3xl sm:text-5xl lg:text-7xl lg:px-3 px-1 font-medium tracking-tight Playfair transition-all duration-200 ${
          isActive ? 'bg-[#f9def9] ' : ''
        }`}
      >
        Simple Strategy. 
      </span>

      {/* "HERE" span */}
      <span
        className={`text-3xl sm:text-5xl lg:text-7xl lg:px-3 px-1 font-medium tracking-tight Playfair transition-all duration-200 ${
          !isActive ? 'bg-[#f9def9]' : ''
        }`}
      >
         Begin Small,
      </span>

</div>
<div className='lg:w-full w-8/12 flex justify-center items-center'> <span className='lg:text-4xl text-xl text-center sm:text-2xl Playfair italic'>but think big - let's scale your business step by step. </span>
</div>
<div className='rotate-90 flex justify-center items-center lg:pt-16 pt-8 w-10 lg:w-16'> 
    <img src={arrowd} alt='arrow' ></img>
</div>

        </div>

<div className='lg:w-40 sm:w-28 w-16 absolute lg:left-28 rotate-90 left-5 lg:-top-5 top-2 opacity-45 z-10'>
    <img src={Graphic1} alt='graphic'></img>
</div>

    </div>
  )
}

export default StartSmall