import Banner from '../assets/2..png'
import In from '../assets/Flags/in.png'
import Sing from '../assets/Flags/singapore.png'
import Indo from '../assets/Flags/indo.webp'
import Mal from '../assets/Flags/mal.jpg'
import Tai from '../assets/Flags/tw.png'
import Dub from '../assets/Flags/dubai.png'
import Hongkong from '../assets/Flags/hk.svg'
import Philippines from '../assets/Flags/pp.png'
import Southkorea from '../assets/Flags/ko.png'
import Vietnam from '../assets/Flags/vi.webp'

export default function SectionWorkshop() {
    const imgs = [
        In, Tai, Sing, Dub, Hongkong, Mal, Indo, Philippines, Southkorea, Vietnam
    ]

    return (
      <div className="min-h-screen pt-10 sm:pt-16 md:pt-20 bg-gradient-to-r font-sans px-4 sm:px-8 md:px-12 lg:px-20 py-10 sm:py-16 md:py-20" style={{
        background: "linear-gradient(to right, rgba(248, 235, 248, 0.99), rgba(220, 210, 235, 0.99), rgba(188, 191, 237, 0.99))",
      }}>
        <div className="max-w-7xl relative px-4 sm:px-8 md:px-12 lg:px-20 mx-auto">
          <div className="grid md:grid-cols-1 lg:grid-cols-2 sm:gap-8 gap-4 z-10 lg:gap-12 items-center">
            <div className="bg-white w-60 sm:w-72 md:w-80 h-40 sm:h-48 md:h-60 -z-10 absolute hidden lg:block">
            </div>
            {/* Left Column - Text Content */}
            <div className="space-y-6 sm:space-y-8 pl-0 sm:pl-4 md:pl-8 lg:pl-10">
              <div className="flex justify-start lg:gap-4 gap-1.5 items-start flex-col">
                <h1 className="text-xl sm:text-2xl bg-white p-2 tracking-wider font-semibold text-gray-900 font-sans">
                  I'M AISH.
                </h1>
                <h2 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl Playfair  text-gray-900">
                  Discover how I help industry leaders, enterprises, and innovators 
                  <span className="relative mx-2 inline-block">
                    expand globally
                    <span
                      className="absolute left-0 w-full bg-[#df72d0] bg-opacity-30 z-10"
                      style={{ height: '35%', bottom: -6 }}
                    ></span>
                  </span>
                  while securing their first customers in new regions.
                </h2>
              </div>
              
              <p className="text-lg sm:text-xl font-normal text-zinc-600">
                With
                <span className="font-bold text-zinc-900"> strategic sales and marketing, </span>
                {' '}
                <span className="">I accelerate growth and broaden market reach.</span>
              </p>
              
              <p className="text-lg sm:text-xl font-normal text-zinc-600">
                Because with the right blend of cutting-edge technology, you create seamless, secure experiences that drive customer loyalty and accelerate growth.
              </p>
              
              <p className="text-lg sm:text-xl font-normal text-zinc-600">
                The best part?
              </p>
              
              <p className="text-lg sm:text-xl font-normal text-zinc-600">
                You don't need a massive budget, a complex tech stack, or a team of experts to get started - just the right guidance and a vision for growth.
              </p>
            </div>
            
            {/* Right Column - Image */}
            <div className="relative flex justify-center items-center mt-8 lg:mt-0">
              <img
                src={Banner}
                alt="Marketing Expert"
                className="lg:w-full w-64 sm:w-6/12"
              />
            </div>
          </div>
          <div className="flex flex-col lg:flex-row mt-8 sm:mt-12 justify-center lg:justify-between items-center sm:items-center w-full">
            <span className="text-sm font-bold text-zinc-800 uppercase tracking-wider mb-4 lg:mb-0">My journey:</span>
            <div className="flex flex-wrap lg:justify-start justify-center lg:items-start gap-2 sm:gap-3 lg:gap-5">
              {imgs?.map((img, index) => (
                <img key={index} src={img} alt={`Flag ${index + 1}`} className="w-12 sm:w-14 lg:w-20" />
              ))}
            </div>
          </div>
        </div>
      </div>
    )
}