import Graphi2 from '../assets/Graphic2.png'
import Graphi3 from '../assets/2m.png'
import Img1 from '../assets/List/1.png'

export const List1 = ({data}) => {
  return (
    <div className='flex  flex-col  lg:flex-row lg:pt-8 pt-44 w-full lg:mt-0  lg:w-7/12 lg:mr-44 justify-center lg:justify-start items-center bg-white pb-8 relative'>
      <div className='w-full lg:w-8/12  flex justify-center lg:justify-start items-center gap-6 flex-col'>
        <h2 className="text-3xl lg:text-4xl font-semibold font-pop leading-10   text-gray-900 text-left px-6 lg:px-0 lg:ml-14">
          {data?.title}
        </h2>
        <div className='my-1 flex justify-center  lg:justify-start items-start w-full'>
          <div className='px-6 lg:pr-4 lg:pl-14 w-full bg-[#dfbced] text-left py-3'>
            <span className='text-xl uppercase tracking-wider font-pop font-semibold text-zinc-700'>{data?.head}</span>
          </div>
        </div>
        <p className="text-zinc-700 px-6 lg:px-0 lg:ml-14 font-pop tracking-[0.015em] font-light text-lg text-left">
          {data?.para1}
        </p>
        <p className="text-zinc-700 font-pop px-6 lg:px-0 lg:ml-14 tracking-[0.015em] font-light text-lg text-left">
          {data?.para2}
        </p>
        <a className="w-full flex justify-center  lg:justify-start" href={data?.link} target="_blank">
          <button className="bg-[#dfbced] hover:bg-[#edbcea] text-zinc-900 lg:w-6/12 w-9/12 lg:ml-14 mt-4 lg:mt-0 font-medium px-6 py-3 transition-colors duration-200 uppercase text-base tracking-widest">
            LEARN MORE
          </button>
        </a>
      </div>
      <div className='lg:mt-0 absolute hidden  lg:-right-52  w-full lg:w-auto lg:flex justify-center'>
        <img src={data?.img} className='w-full max-w-[384px] lg:w-96' alt={data?.title} style={{
          filter: "drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.8))",
        }} />
      </div>
      
      <div className='lg:mt-0 absolute -top-24  w-full lg:w-auto lg:hidden flex justify-center'>
        <img src={data?.img} className='w-full max-w-[300px] lg:w-96' alt={data?.title} style={{
          filter: "drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.8))",
        }} />
      </div>

    </div>
  )
}
export const List2 =({data})=>{
  return (
<div className='flex w-7/12 ml-44 justify-end items-center bg-white py-8 relative'>
<div className='w-10/12 flex ml-56  justify-end items-center gap-6 flex-col'>

<h2 className="text-2xl overflow-hidden pr-16 lg:text-4xl font-semibold font-pop leading-relaxed text-gray-900">
{data?.title} 
              </h2>
              <div className='my-1 overflow-hidden  flex justify-start items-start w-full'>
              <div className=' bg-[#dfbced] w-full px-4 text-left py-3'>
              <span className='text-xl   uppercase tracking-wider font-pop font-semibold text-zinc-700'>{data?.head}
              </span>
              </div>
              </div>
              <p class="text-zinc-700  pr-16 font-pop tracking-[0.015em] font-light text-lg">
              {data?.para1} 
    </p>
    <p class="text-zinc-700  font-pop  pr-16 tracking-[0.015em] font-light text-lg">
    {data?.para2} </p>
      <a className="w-full" href={data?.link}  target="_blank"><button className="bg-[#dfbced]  hover:bg-[#edbcea] text-zinc-900 w-6/12 font-medium px-6 pr-16 py-3  transition-colors duration-200 uppercase text-base tracking-widest">
          LEARN MORE             </button></a> 
          
</div>

<div className='absolute -left-52 '>
    <img src={data?.img} className='w-96' alt={data?.title}  style={{
    filter: "drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.8))",
  }}></img>
</div>

<div className='absolute -right-72 -top-44 opacity-40 '>
    <img src={Graphi2} className='w-80' alt='block'  style={{
    // filter: "drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.8))",
  }}></img>
</div>


</div>
  )
  }