import React from 'react';
import about from '../assets/Research page banner.jpg'
const ResearchHero = () => {
  return (
    <div className="w-full relative h-screen overflow-hidden"  
    // style={{
    //     background: "radial-gradient(circle, rgba(255, 182, 193, 0.3) 5%, rgba(255, 182, 193, 0.2) 60%, rgba(188, 191, 237, 0.8) 100%)"
    //   }}
      
      
      >
      {/* Background Image */}
      <div className="absolute -z-10 top-4 left-0 w-full h-full">
        <img
          src={about}
          className="h-full w-full scale-105 object-cover"
          alt="Background"
        />
      </div>

      
    </div>
  );
};

export default ResearchHero;
