


  'use client'

import React, { useEffect, useState } from 'react'
import { FaCalendarAlt } from "react-icons/fa"
import Call from '../assets/Call.png'
import { Link } from 'react-router-dom'

export default function Meeting2() {
  const [isCalendlyReady, setIsCalendlyReady] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const script = document.createElement('script')
    script.src = "https://assets.calendly.com/assets/external/widget.js"
    script.async = true
    script.onload = () => {
      console.log('Calendly script loaded')
      setIsCalendlyReady(true)
    }
    script.onerror = () => {
      console.error('Failed to load Calendly script')
    }
    document.body.appendChild(script)

    const link = document.createElement('link')
    link.href = "https://assets.calendly.com/assets/external/widget.css"
    link.rel = "stylesheet"
    document.head.appendChild(link)

    return () => {
      document.body.removeChild(script)
      document.head.removeChild(link)
    }
  }, [])

  const openCalendly = (e) => {
    e.preventDefault()
    console.log('Button clicked, Calendly ready:', isCalendlyReady)
    if (isCalendlyReady && window.Calendly) {
      setIsLoading(true)
      try {
        window.Calendly.initPopupWidget({
          url: 'https://calendly.com/dr-aishwarya-gowda/30min',
          onClose: () => {
            console.log('Calendly popup closed')
            setIsLoading(false)
          }
        })
      } catch (error) {
        console.error('Error initializing Calendly widget:', error)
        setIsLoading(false)
        alert('An error occurred while opening the scheduling system. Please try again.')
      }
    } else {
      console.error('Calendly is not ready yet')
      alert('Sorry, the scheduling system is still loading. Please try again in a few seconds.')
    }
  }

  return (
    <div className="w-full flex   justify-center items-center">
      <div className="bg-[#bcbfed] overflow-visible w-full lg:w-8/12 py-10 px-4 md:px-8 lg:px-16 relative ">
        <div className="lg:max-w-5xl w-full relative overflow-visible mx-auto flex flex-col md:flex-row items-center">
          <div className="absolute lg:-right-48 -top-24 lg:-top-5   md:mb-0">
            <img
              src={Call}
              alt="Email List Guide Pages"
              className="h-auto lg:max-w-[250px] max-w-[180px]"
            />
          </div>
          <div className="lg:pr-28 lg:pt-0 lg:text-left text-center pt-28 text-zinc-800">
           
            <h1 className="sm:text-4xl text-3xl lg:text-5xl Playfair leading-tight lg:mb-10 mb-5">
              To explore how we can drive growth and scale your business. <span className="text-yellow-300"></span>
            </h1>
            <div className="flex flex-col lg:items-start items-center   gap-2 mb-0 lg:mb-4">
            <Link to='/contact'> <button className="bg-white hover:bg-zinc-100 text-gray-900 flex justify-center items-center gap-2 font-medium py-3 px-16  transition-colors duration-200 uppercase text-sm tracking-wider">
 Contact us
              </button></Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}