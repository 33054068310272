import React from 'react'
import Video from '../assets/thirdbanner.mp4'
import Meeting from './Meeting'
import AnimatedText from './AnimatedText'

const ThirdHero = () => {
  return (
    <div
      className="relative mt-16 min-h-screen w-full flex justify-center items-center overflow-hidden px-4 sm:px-8 md:px-16 lg:px-24 xl:px-48"
      style={{
        background: "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.8), rgba(188, 191, 237, 0.05))",
      }}
    >
      <video
        id="video1"
        autoPlay
        loop
        muted
        playsInline
        src={Video}
        type="video/mp4"  
        className="absolute inset-0 w-full h-full object-cover object-center sm:object-right -z-20"
      />

      <div className='flex justify-start w-full items-start flex-col z-10'>
        <div className='w-full lg:w-7/12  xl:w-6/12 relative'>
          <div className="flex flex-col items-center ">
            <div className="text-center  flex flex-col items-center ">
              <h1 className="text-3xl sm:text-4xl md:text-5xl font-semibold Playfair text-zinc-700 mb-3">
                IMPACTING INNOVATION, <br className="hidden sm:inline" /> INSPIRING GLOBAL GROWTH
              </h1>
              <p className="text-lg sm:text-xl w-full sm:w-10/12 text-center font-pop font-semibold tracking-wide text-zinc-800 mt-3 sm:mt-5">
                <span className="relative hidden lg:inline-block text-xl ">
                  Insights That Transform Ideas Into Action
                  <span
                    className="absolute left-0 w-full bg-pink-700/20 -z-10"
                    style={{ height: '40%', bottom: 0 }}
                  ></span>
                </span>

                <span className="relative lg:hidden inline-block text-base ">
                  Insights That Transform 
                  <span
                    className="absolute left-0 w-full bg-pink-700/20 -z-10"
                    style={{ height: '40%', bottom: 0 }}
                  ></span>
                </span>
                <span className="relative lg:hidden inline-block text-base ">
                Ideas Into Action                  <span
                    className="absolute left-0 w-full bg-pink-700/20 -z-10"
                    style={{ height: '40%', bottom: 0 }}
                  ></span>
                </span>
               
              </p>
            </div>
            <div className="mt-3 sm:mt-5 text-left space-y-4 sm:space-y-5 w-full sm:w-10/12 lg:w-9/12">
              <p className="text-zinc-700 font-pop tracking-[0.015em] font-light text-base sm:text-lg">
                With a passion for simplifying cutting-edge technology, I share actionable strategies through global speaking engagements and collaborations.
              </p>
              <p className="text-zinc-700 font-pop tracking-[0.015em] font-light text-base sm:text-lg">
                From exploring AI's transformative potential to advancing Web3 solutions and scaling brands worldwide, I empower leaders and innovators to drive meaningful change and lead with confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThirdHero