import React from 'react'
import Video from '../assets/video.webm'
import Meeting from './Meeting'
import AnimatedText from './AnimatedText'

const Hero = () => {
  return (
    <div
      className="min-h-screen relative mt-16 flex justify-center items-start w-full"
      style={{
        background: "linear-gradient(to right, rgba(248, 235, 248, 0.80), rgba(188, 191, 237, 0.80))",
      }}
    >
      <video
        id="video1"
        autoPlay
        loop
        muted
        src={Video}
        type="video/mp4"  
        className="object-cover -z-20 w-full h-full absolute"  
      />

      <div className='flex justify-center pt-32 lg:pt-40 items-center flex-col gap-6 sm:gap-8 md:gap-10 lg:gap-14 px-4 sm:px-6 md:px-8'>
        <div className='flex justify-center items-center gap-0 flex-col'>
          <span className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-medium text-center Playfair">
            Looking to scale your business globally?
          </span>
          <span className="text-3xl sm:text-4xl md:text-5xl lg:text-7xl italic font-medium text-center Playfair py-2 sm:py-3 md:py-4">
            I'm Dr. Aishwarya Gowda
          </span>
          <AnimatedText />
        </div>
        <p className="text-[#6d076d] uppercase tracking-widest text-sm sm:text-base md:text-lg font-semibold text-center px-2 sm:px-4">
          Empowering businesses with secure, scalable solutions for the GLOBAL MARKETS.
        </p>
      </div>

      <div className="flex absolute lg:-bottom-24  z-20 -bottom-24 w-full">
        <Meeting />
      </div>
    </div>
  )
}

export default Hero;