import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Logo from '../assets/Logo.png';
import { FaInstagram, FaTwitter, FaFacebook, FaLinkedinIn, FaFacebookF, FaSquareInstagram, FaLinkedin } from 'react-icons/fa6';
import { Link, useLocation } from 'react-router-dom';
import { FaFacebookSquare, FaWhatsapp, FaWhatsappSquare } from 'react-icons/fa';

const Header = () => {
  const { pathname } = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const navItems = [
    { path: '/', label: 'HOME' },
    { path: '/about', label: 'ABOUT' },
    { path: '/chronicles', label: 'CHRONICLES' },
    { path: '/research', label: 'RESEARCH' },
    { path: '/contact', label: 'CONTACT' },
  ];

  const socialLinks = [
    { icon: FaLinkedin, href: 'https://www.linkedin.com/in/dr-aishwarya-gowda/' },
    { icon: FaSquareInstagram, href: 'https://www.instagram.com/dr.aishwarya.gowda/' },
    { icon: FaWhatsappSquare, href: 'https://wa.me/qr/2EVNCVPHF2MKI1' },
    { icon: FaFacebookSquare, href: 'https://www.facebook.com/profile.php?id=100013426633763' },
  ];

  return (
    <header className="w-full shadow-md fixed top-0 bg-white z-50">
      <div className="flex justify-between items-center px-4 md:px-20 py-3">
        <Link to="/">
          <img src={Logo} className="w-16 md:w-20" alt="Logo" />
        </Link>

        {/* Desktop Navigation */}
        <nav className="hidden lg:flex gap-14 justify-center items-center">
          {navItems.map((item) => (
            <Link key={item.path} to={item.path}>
              <span
                className={`text-base transition-all duration-500 ease-in-out cursor-pointer tracking-wide text-zinc-700 hover:text-[#a23ab2] hover:border-b-[#a23ab2] border-b-2 ${
                  pathname === item.path ? 'border-b-[#a23ab2]' : 'border-b-transparent'
                }`}
              >
                {item.label}
              </span>
            </Link>
          ))}
        </nav>

        {/* Desktop Social Icons */}
        <div className="hidden lg:flex gap-8 justify-center items-center">
          {socialLinks.map((link, index) => (
            <a
              key={index}
              href={link.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <link.icon className="text-3xl transition-all duration-500 ease-in-out hover:text-[#a23ab2] cursor-pointer" />
            </a>
          ))}
        </div>

        {/* Mobile Menu Button */}
        <button
          className="lg:hidden z-50 relative w-10 h-10 text-gray-500 hover:text-gray-700 focus:outline-none"
          onClick={toggleMenu}
        >
          <span className="sr-only">Open main menu</span>
          <div className="absolute left-1/2 top-1/2 block w-5 transform -translate-x-1/2 -translate-y-1/2">
            <span
              aria-hidden="true"
              className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                isMenuOpen ? 'rotate-45' : '-translate-y-1.5'
              }`}
            ></span>
            <span
              aria-hidden="true"
              className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                isMenuOpen ? 'opacity-0' : 'opacity-100'
              }`}
            ></span>
            <span
              aria-hidden="true"
              className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                isMenuOpen ? '-rotate-45' : 'translate-y-1.5'
              }`}
            ></span>
          </div>
        </button>
      </div>

      {/* Mobile Navigation Panel */}
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ x: '100%' }}
            animate={{ x: 0 }}
            exit={{ x: '100%' }}
            transition={{ type: 'spring', stiffness: 300, damping: 30 }}
            className="fixed top-0 right-0 bottom-0 z-40 w-64 bg-white shadow-xl"
          >
            <nav className="flex flex-col h-full justify-center items-center space-y-8">
              {navItems.map((item) => (
                <motion.div
                  key={item.path}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 }}
                >
                  <Link
                    to={item.path}
                    onClick={() => setIsMenuOpen(false)}
                    className={`text-lg font-medium ${
                      pathname === item.path ? 'text-[#a23ab2]' : 'text-zinc-700'
                    } hover:text-[#a23ab2]`}
                  >
                    {item.label}
                  </Link>
                </motion.div>
              ))}
              <motion.div 
                className="flex gap-4 mt-8"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                {socialLinks.map((link, index) => (
                  <a
                    key={index}
                    href={link.href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <link.icon className="text-2xl text-zinc-700 hover:text-[#a23ab2]" />
                  </a>
                ))}
              </motion.div>
            </nav>
          </motion.div>
        )}
      </AnimatePresence>
    </header>
  );
};

export default Header;