import React, { useState, useEffect } from 'react'
import { IoMdClose } from 'react-icons/io'
import { FiSearch } from 'react-icons/fi'
import Award1 from '../assets/Awards/google_analytics_certified.png'
import Award2 from '../assets/Awards/Servicenow.jpeg'
import Award3 from '../assets/Awards/ms.jpeg'
import Award4 from '../assets/Awards/link.jpeg'
import IEEEMadras from '../assets/Awards/IEEE Madras.jpeg'

import Lt from '../assets/Awards/L&T.jpeg'
import VISAI from '../assets/Awards/VISAI.jpeg'
import Irf from '../assets/Awards/IRF.jpeg'
import TwoIEEE from '../assets/Awards/2ndiee.png'
import threeieee from '../assets/Awards/3rd ieee.png'
import threeiot from '../assets/Awards/3rd iot.jpeg'
import final from '../assets/Awards/fina.jpeg'

import fourieeeiot from '../assets/Awards/4th ieee iot.png'
import fourieee from '../assets/Awards/4th ieee.png'
import fiveieee23 from '../assets/Awards/5th ieee 23.png'
import fiveieee from '../assets/Awards/5th ieee.png'

import sessionchair from '../assets/Awards/session chair.png'



import nfu from '../assets/Awards/NFU incubator.png'
import startup from '../assets/Awards/stratup land.png'
import nrc from '../assets/Awards/NRC robotics.avif'




// Demo data
const tools = [

 
  
    // CERTIFICATES
    {
      id: 6,
      name: 'Google Analytics',
      title: 'Google Analytics Certification',
      category: 'CERTIFICATES',
      description: 'Demonstrates expertise in leveraging Google Analytics to analyze user behavior, optimize digital performance, and drive data-driven decisions for marketing strategies.',
      logo: Award1,
      buttonText: 'Show credential',
      link: 'https://skillshop.credential.net/80e58c70-041f-4889-abe3-686fb04d6a7a'
    },
    {
      id: 7,
      name: 'Sustainable Tech',
      title: 'Career Essentials in Sustainable Tech by Microsoft and LinkedIn',
      category: 'CERTIFICATES',
      description: 'Highlights proficiency in integrating sustainable business strategies, digital sustainability, and green technologies to foster environmentally responsible innovations.',
      logo: Award3,
      buttonText: 'Show credential',
      link: 'https://www.linkedin.com/learning/certificates/aa06c8b634ffbaa92f6050fd9c26a289540b754a47244141f54b4a2275bd8e66?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
    {
      id: 8,
      name: 'Digital Business Analysis',
      title: 'Learning Digital Business Analysis',
      category: 'CERTIFICATES',
      description: 'Equips with core competencies in analyzing business needs, identifying solutions, and enhancing processes for digital transformation and growth.',
      logo: Award4,
      buttonText: 'Show credential',
      link: 'https://www.linkedin.com/learning/certificates/97bc17111b37963c8435dc17cf8bc75107726791a8629b27ca7804ed85a48814?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
    {
      id: 9,
      name: 'IT Leadership',
      title: 'ServiceNow IT Leadership Professional Certificate',
      category: 'CERTIFICATES',
      description: 'Validates skills in IT project and program management, strategic planning, and architecture design to lead IT initiatives effectively.',
      logo: Award2,
      buttonText: 'Show credential',
      link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
   
  
    // AWARDS
    {
      id: 26,
      name: 'Session Chairman',
      title: 'Best Conference Paper Award - 5th Eurasia Conference (October 2023)',
      category: 'AWARDS',
      description: `Received for presenting innovative research on leveraging natural language processing to analyze emotional nuances in textual data.
  `,
      logo: fiveieee23,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
    {
      id: 25,
      name: 'Session Chairman',
      title: 'Best Conference Paper Award - 4th IEEE Eurasia (October 2022)',
      category: 'AWARDS',
      description: `Recognized for advancing hiring processes through the integration of artificial intelligence and blockchain technologies.`,
      logo: fourieeeiot,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
   
    {
      id: 11,
      name: 'Session Chairman',
      title: 'Session Chairman for Onsite Oral Paper Session (IEEE ECICE, Oct 2022)',
      category: 'AWARDS',
      description: `Served as a session chairman, moderating discussions and providing insights at IEEE ECICE's oral paper presentations.`,
      logo: sessionchair,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
    {
      id: 12,
      name: 'Session Chairman',
      title: 'Best Conference Paper Award - 2nd IEEE International Conference (Jul 2022)',
      category: 'AWARDS',
      description: `Recognized for excellence in presenting innovative research on electronic communications, IoT, and big data.
`,
      logo: TwoIEEE,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 13,
      name: 'Session Chairman',
      title: 'Best Conference Paper Award - 4th IEEE Eurasia Conference (May 2022)',
      category: 'AWARDS',
      description: `Honored for presenting groundbreaking research, contributing to advancements in electronic communications and technology.
`,
      logo: fourieee,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 14,
      name: 'Session Chairman',
      title: 'Best Conference Paper Award - 5th IEEE Eurasian Conference (Feb 2022)',
      category: 'AWARDS',
      description: `Awarded for delivering impactful research that highlights innovative solutions in technology and communication.
`,
      logo: fiveieee,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 15,
      name: 'Session Chairman',
      title: '2021 創新創業競賽 (Startup Land, Nov 2021)',
      category: 'AWARDS',
      description: `Recognized for entrepreneurship and innovation at the prestigious Startup Land competition.
`,
      logo: startup,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 16,
      name: 'Session Chairman',
      title: 'Finalist - 2021 International University Innovation & Entrepreneurship Competition',
      category: 'AWARDS',
      description: `Achieved finalist status, showcasing creativity and impactful solutions in innovation and entrepreneurship.
`,
      logo: final,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
    
    {
      id: 17,
      name: 'Session Chairman',
      title: 'Best Conference Paper Award - 3rd IEEE Eurasia Conference (Nov 2021)',
      category: 'AWARDS',
      description: `Commended for presenting high-impact research on advanced technologies and practical implementations.
`,
      logo: threeieee,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 18,
      name: 'Session Chairman',
      title: 'Best Conference Paper - Water Purification IoT System (Jul 2021)',
      category: 'AWARDS',
      description: `Received accolades for a paper on integrating IoT with water purification technologies to enhance efficiency.
`,
      logo: threeiot,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 19,
      name: 'Session Chairman',
      title: '2020 NFU Tiger Incubator Competition Winner (Sep 2020)',
      category: 'AWARDS',
      description: `Won NFU's prestigious competition for innovative startups, showcasing entrepreneurial excellence.
`,
      logo: nfu,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 20,
      name: 'Session Chairman',
      title: 'Excellent Paper Award - IRF (Oct 2019)',
      category: 'AWARDS',
      description: `Received the IRF award for the paper 'Speed Control of Automobiles Using Seat Belt,' commending both presentation and content.
`,
      logo: Irf,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    }, {
      id: 21,
      name: 'Session Chairman',
      title: 'NRC Robotics Excellence Award - IIT Bombay (Apr 2015)',
      category: 'AWARDS',
      description: `Earned the Excellence Award at NRC Robotics Finale for demonstrating exceptional robotic engineering skills.
`,
      logo: nrc,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },






    {
      id: 22,
      name: 'Session Chairman',
      title: 'IEEE Madras - 2nd Prize for Underwater Crack Detection (2016)',
      category: 'AWARDS',
      description: `Awarded for innovative research in underwater crack detection using image processing techniques.
`,
      logo: IEEEMadras,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },{
      id: 23,
      name: 'Session Chairman',
      title: 'L&T TECHgium™ Finalist for Speed Control Using Seat Belt (2018)',
      category: 'AWARDS',
      description: `Selected among the top 20 projects from over 7,000 entries at L&T TECHgium™, leading to an Associate Engineer placement.
`,
      logo: Lt,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },{
      id: 24,
      name: 'Session Chairman',
      title: 'VISAI-2017 3rd Prize - Underwater Structural & Biological Monitoring',
      category: 'AWARDS',
      description: `Secured 3rd prize at VISAI-2017 for innovative use of ROUV in underwater monitoring.`,
      logo: VISAI,
      buttonText: '',
       link:'https://www.linkedin.com/learning/certificates/e5268ed8814efec6cd99fcdeaa5f6939683f820f710a2b694e24053c3fff19f1?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_certifications_details%3Bvio%2BzB%2BsQLm7eMcfG7ywog%3D%3D'
    },
    
  ]
  

const categories = ['CERTIFICATES', 'AWARDS']

export default function Awards() {
  const [activeCategory, setActiveCategory] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredTools, setFilteredTools] = useState(tools)

  useEffect(() => {
    let filtered = tools

    if (activeCategory) {
      filtered = filtered.filter(tool => tool.category === activeCategory)
    }

  

    if (searchQuery) {
      filtered = filtered.filter(tool => 
        tool.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        tool.description.toLowerCase().includes(searchQuery.toLowerCase())
      )
    }

    setFilteredTools(filtered)
  }, [activeCategory, searchQuery])

  return (
    <div className="max-w-6xl mx-auto sm:px-12 lg:px-5 px-4 py-8 lg:py-12 font-sans">
      <h1 className="text-center font-pop uppercase lg:text-xl text-lg font-semibold text-gray-700 sm:mb-10 mb-8 lg:mb-12">
      Professional Milestones      </h1>

      <div className="flex flex-wrap gap-4 justify-between items-center mb-6 lg:mb-8">
        <div className="flex  flex-wrap gap-4">
          {/* Category Filters */}
          {categories.map(category => (
            <button
              key={category}
              onClick={() => setActiveCategory(activeCategory === category ? '' : category)}
              className={`px-6 py-2 rounded-full uppercase flex items-center gap-2 ${
                activeCategory === category
                ? 'bg-purple-500/90 text-zinc-50' 
                : 'bg-purple-100/80 text-gray-700 hover:bg-purple-200'
              }`}
            >
              {category}
              {activeCategory === category && (
                <IoMdClose className="w-4 h-4" />
              )}
            </button>
          ))}

          {/* Stage Filters */}
      

        
        </div>

        {/* Search */}
        <div className="relative w-4/12 flex-grow md:flex-grow-0">
          <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
          <input
            type="text"
            placeholder="Search Tools"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full  pl-10 pr-4 py-2 rounded-full bg-purple-100 focus:outline-none focus:ring-2 focus:ring-zinc-800"
          />
        </div>
      </div>

      {/* Tools Grid */}
      <div className="space-y-8 py-10">
        {filteredTools.map(tool => (
          <div key={tool.id} className="border-b pb-8">
            <div className="flex flex-col md:flex-row items-start gap-8">
              <img
                src={tool.logo}
                alt={tool.title}
                className="w-36 h-36 object-contain"
              />
              <div className="flex-1 flex flex-col">
                <div className="text-base  font-medium mb-2">
                  <span className='text-zinc-800 pop font-medium tracking-wider px-6 py-1.5 bg-purple-100'>{tool.category}</span>
                </div>
                <h2 className="text-3xl Playfair font-medium mb-4">{tool.title}</h2>
                <p className="text-gray-600 pop text-sm mb-4 flex-grow">
                  {tool.description}
                </p>
                
              </div>
              <div className="flex justify-center  md:w-3/12 lg:w-3/12  w-full items-center h-full">
             {tool.buttonText !== '' &&   <a href={tool?.link} target='_blank'> <button className="bg-purple-400/40 text-sm tracking-wide hover:bg-purple-500/50 text-black px-8 py-3  font-medium">
                    {tool.buttonText}
                  </button></a>}
                </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}