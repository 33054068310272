import React from 'react'
import Grp from '../assets/grppic.png'
import Main from '../assets/2..png'

export default function About3() {
  return (
    <div className="min-h-screen relative bg-gradient-to-br lg:py-0 pt-16 from-white via-mint-50 to-sage-50 ">
          <div className=" absolute bottom-0 -z-10 w-full h-[800px] lg:h-[500px]" style={{  background: "linear-gradient(to right, rgba(188, 191, 237, 0.99), rgba(220, 210, 235, 0.99), rgba(188, 191, 237, 0.99))"
}}
></div>

      <div className="max-w-7xl lg:pb-10 pb-6 mx-auto ">
       
        <div className="grid grid-cols-1 lg:px-4 sm:px-16 px-6   lg:grid-cols-2 lg:gap-8 gap-2 items-center">
          {/* Left Column */}
          <div className="lg:space-y-8">
            <div className='lg:pl-20'>
            <span className="text-2xl w-full text-center  md:text-3xl lg:text-4xl Playfair ">
              I've amplified brand awareness and lead generation by 
              
              
              
              <span className="relative lg:mx-2 mx-0.5 inline-block">
              100x
  <span
    className="absolute left-0 w-full  bg-[#e65fd4] -z-10"
    style={{ height: '40%', bottom: -5 }}
  ></span>
</span>
              
              
              
               and website traffic by 
               
                         
              <span className="relative lg:mx-2 mx-0.5 inline-block">
              15x
  <span
    className="absolute left-0 w-full  bg-[#e65fd4] -z-10"
    style={{ height: '40%', bottom: -5 }}
  ></span>
</span>
            
                in just six months, achieving top rankings.              
            </span>
            </div>
            
            <div className="relative w-full  hidden lg:flex justify-center items-end  md:aspect-[4/3]">
              <img 
                src={Grp}
                alt="Team on beach"
                className="rounded-2xl object-cover  lg:w-96"
              />
            </div>
            <div className="relative flex lg:hidden py-4 justify-center items-center w-full aspect-[6/4]">
              <img 
                src={Main} 
                alt="Business woman with book"
                className="rounded-2xl object-cover w-64 lg:w-96"
              />
             
            </div>
          </div>

          {/* Right Column */}
          <div className="space-y-8  lg:flex-nowrap flex-wrap-reverse">
            <div className="relative lg:flex hidden  justify-center items-center w-full aspect-[6/4]">
              <img 
                src={Main} 
                alt="Business woman with book"
                className="rounded-2xl object-cover w-64 lg:w-96"
              />
             
            </div>
            <div className="relative  lg:hidden flex justify-center items-end ">
              <img 
                src={Grp}
                alt="Team on beach"
                className="rounded-2xl object-cover w-80 lg:w-96"
              />
            </div>

            <div className="space-y-6 flex flex-col">
              
            <span className="text-2xl md:text-3xl lg:text-4xl Playfair ">
              Academic Achievements Awarded the prestigious
              
              
              
              
              <span className="relative lg:mx-2 mx-0.5 font-semibold inline-block">
              MOST Outstanding
  <span
    className="absolute left-0 w-full bg-opacity-30 bg-[#e65fd4] -z-10"
    style={{ height: '40%', bottom: -5 }}
  ></span>
</span>
<span className="relative lg:mx-2 mx-0.5 font-semibold  inline-block">
Doctoral Scholarship  <span
    className="absolute left-0 w-full bg-opacity-30 bg-[#e65fd4] -z-10"
    style={{ height: '40%', bottom: -5 }}
  ></span>
</span>

              and MOE scholarship, with the MOST scholarship being granted to me as the sole recipient, highlighting my dedication to academic excellence.
</span>



              <a className="w-full lg:block flex justify-center items-center"  href="https://www.linkedin.com/in/dr-aishwarya-gowda/" target="_blank"><button className="bg-[#bcbfed] lg:mt-8 mt-5 hover:bg-[#b0b4ed] text-zinc-900 w-12/12 font-medium px-12 py-3 transition-colors duration-200 uppercase text-sm lg:text-base tracking-widest">
          Connect on LinkedIn              </button></a> 
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}