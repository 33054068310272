

import React, { useState, useEffect } from 'react';
import T1 from '../assets/Testimonials/Dr. Sanmugasundaram.jpeg';
import T2 from '../assets/Testimonials/Sivaperumal.jpeg';
import T3 from '../assets/Testimonials/Dr. Suresh.jpeg';
import T4 from '../assets/Testimonials/Dr Vairavel.jpeg';
import T5 from '../assets/Testimonials/Dr. Balasubramanian.jpeg';


import comma from '../assets/comma.png'
const testimonials = [

  {
    name: "Dr. Sanmugasundaram Ravichandran",
    image: T1, // Replace with actual image import
    title: "Embedded Electronics | VLSI Design | Antenna Design",
    quote: "A highly deserved technical & administrative person with updated skills and understanding the current technology world."
  },
  {
    name: "Dr.Sivaperumal Sampath",
    image: T2, // Replace with actual image import
    title: "Dean Academics & Director - International Relations",
    quote: "I know Aishwarya for the past 7 years since she joined in B.Tech. She was one of the smart students and always shown interest in learning new things & curios in exploring the opportunities. Aishwarya and Team have been involving in lot of projects and not only took part in various competitions and won many prizes also, which brought lot of laurels to the Institution and to the department. \n I remember, she undertook her Research Internship at NFU Taiwan along with 14 other students and she was the only girl in the team, which is an evident that she is very bold in taking decisions and executing her plans. She is multi talented and i wish her a great future ahead",

  },
  {
    name: "Dr.Suresh Ponnan",
    image: T3, // Replace with actual image import
    title: "Professor and Dean International Relations",
    quote: "Aishwarya was an amazing individual with high innovative skills. Her energy as a team leader to accomplish things was contagious and it helped us achieve great goals. One of the best students we have had for sure. She is well organized, diligent, and a fast learner. All these attributes were critical when trying to compliment research and academics side by side. Her deliveries were always very well executed, on time and flawless. She is creative, smart and has excellent communication skills. \n I'm sure she will be a great asset to any team she joins",
    
  },
  {
    name: "Dr. Vairavel Gurusamy",
    image: T4, // Replace with actual image import
    title: "Dean Academics and Educational Initiatives | Member-at-Large, CDIO International Council",
    quote: "Aishwarya Gowda is proficient in studies, problem solving, embedded programming and developing IOT devices. She is one among the toppers in her batch. Her communication and team work skills are highly commendable. In short she is a tech savvy and spent lot of time in projects."
  },
  {
    name: "Dr. Balasubramanian Esakki",
    image: T5, // Replace with actual image import
    title: "Dean Centre for International Affairs, NITTTR",
    quote: "I'm delighted to recommend Ms. Aishwarya Gowda. I had the pleasure of working with her in few research projects for 2 years. Aishwarya has deep passion towards research and academics and has been one of the few exceptionally gifted students that I have encountered in my career. From the beginning, Aishwarya impressed me with her ability to deal with complex research and her passion for research.\n Aishwarya is a talented person, and she has my highest recommendation. I wish her all the best.",
  
  }
];

export default function TestimonialCarousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div className="bg-[#f6f2f8] lg:py-10 sm:py-8 py-3  flex justify-center items-center relative px-4">
      <div className=" absolute  rounded-full top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <img src={comma} className='lg:w-32 sm:w-28 sm:h-28 w-20 h-20 lg:h-32' alt='comma'></img>
</div>


      <div className="max-w-4xl mx-auto  pt-10   relative overflow-hidden">
        <div 
          className="flex transition-transform  overflow-visible duration-500 ease-in-out"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {testimonials.map((testimonial, index) => (
            <div key={index} className="w-full  flex justify-center items-center  overflow-visible flex-shrink-0">
              {/* White card with content */}
              <div className="bg-white overflow-visible lg:px-8 sm:px-6 sm:py-6 sm:pt-10 px-4 py-4 pt-8 lg:py-8 lg:pt-12">
                {/* Profile image and name */}
                <div className="flex justify-center overflow-visible  relative z-20 mb-1">
  <div className="relative overflow-visible ">
    <img
      src={testimonial.image}
      alt={testimonial.name}
      className="lg:w-40  lg:h-40 sm:w-32 sm:h-32 w-28 h-28 rounded-full  object-cover"
      style={{ marginTop: '-50%' }} // Moves the image half above the container
    />
  </div>
</div>


                <div className="text-center mb-2 lg:mb-3">
                  <h3 className="text-[#464646] tracking-wider font-dancing lg:text-3xl sm:text-2xl text-xl font-semibold">
                    {testimonial.name}
                  </h3>
                </div>
                {/* Title */}
                <div className="text-center lg:mb-4 mb-2 sm:mb-3">
                  <h3 className="text-[#ab5ec3] tracking-wider font-pop lg:text-xl sm:text-lg text-base font-semibold uppercase">
                    {testimonial.title.split('+').map((part, i) => (
                      <React.Fragment key={i}>
                        {part.trim()}
                        {i < testimonial.title.split('+').length - 1 && <br />}
                      </React.Fragment>
                    ))}
                  </h3>
                </div>

                {/* Quote */}
                <blockquote className="text-center ">
                  <p className="font-pop sm:text-lg lg:text-xl text-base font-medium  text-zinc-700">
                    {testimonial.quote.split('\n').map((line, i) => (
                      <React.Fragment key={i}>
                        "{line}"
                        <br />
                      </React.Fragment>
                    ))}
                   
                  </p>
                </blockquote>
              </div>
            </div>
          ))}
        </div>

        {/* Navigation dots */}
        <div className="flex justify-center lg:pt-4 pt-3 pb-1.5 lg:pb-2">
          {testimonials.map((_, index) => (
            <button
              key={index}
              className={`w-4 h-4 rounded-full mx-2 focus:outline-none transition-all duration-300 ${
                index === currentIndex 
                  ? 'bg-[#ab5ec3] scale-125' 
                  : 'bg-gray-300 hover:bg-[#ab5ec3] hover:scale-110'
              }`}
              onClick={() => setCurrentIndex(index)}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </div>
  );
}