import React from 'react'
import Graphi2 from '../assets/2m.png'
import Graphi1 from '../assets/4.png'


const About2 = () => {
  return (
    <div className='w-full flex justify-center relative items-center  flex-col lg:pt-10 sm:pt-8 sm:pb-16 pt-6 pb-10 lg:pb-20 overflow-hidden'>
<div className='absolute lg:top-4 -top-14 -left-8 lg:left-0 opacity-30 '>
    <img src={Graphi1} className='lg:w-80 sm:w-56 w-40'  style={{
    // filter: "drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.8))",
  }}></img>
</div>
<div className='absolute lg:bottom-0 -bottom-8 rotate-90 lg:right-0 -right-16 opacity-30 '>
    <img src={Graphi1} className='lg:w-80 sm:w-56 w-40'  style={{
    // filter: "drop-shadow(0px 12px 18px rgba(0, 0, 0, 0.8))",
  }}></img>
</div>
<div className='flex justify-center lg:gap-6 gap-4 lg:w-7/12 w-full items-center  flex-col'>
<span className="text-xl sm:text-2xl w-9/12 text-zinc-700 md:text-3xl font-pop mt-6 sm:mt-8 md:mt-10 text-center tracking-wide font-semibold">
           With over a decade of experience as a technology innovator and researcher, and 4+ years as a Global Sales and Marketing expert...
          </span>

          <div className="lg:w-[2px] w-[1.5px] lg:h-20 h-10  bg-gray-900 mx-auto"></div>


          {/* <span className="text-xl sm:text-2xl text-zinc-800 md:text-4xl tracking-wide Playfair font-  w-full text-center font-medium">
          I have transformed ideas into user-centric, market-ready products, driving businesses to achieve global reach
          </span>   */}

          <div className="text-4xl hidden lg:block md:text-6xl text-center Playfair text-gray-900">
          I have transformed ideas into 
         
          
            <span className="relative mx-2 inline-block">
            user-centric, market-ready 
  <span
    className="absolute left-0 w-full  bg-[#ec92e0] bg-opacity-30 -z-10"
    style={{ height: '35%', bottom: -6 }}
  ></span>
</span>

                   
            <span className="relative mx-2 inline-block">
            products, driving businesses
            <span
    className="absolute left-0 w-full  bg-[#ec92e0] bg-opacity-30 -z-10"
    style={{ height: '35%', bottom: -6 }}
  ></span>
</span>
             

          {' '}achieve global reach.
        </div>

        <div className="sm:text-4xl text-3xl w-10/12 lg:hidden block lg:text-6xl text-center Playfair text-gray-900">
          I have transformed ideas into 
         
          
            <span className="relative mx-2 inline-block">
            user-centric, 
  <span
    className="absolute left-0 w-full  bg-[#ec92e0] bg-opacity-30 -z-10"
    style={{ height: '35%', bottom: -6 }}
  ></span>
</span>
<span className="relative mx-2 inline-block">
market-ready 
  <span
    className="absolute left-0 w-full  bg-[#ec92e0] bg-opacity-30 -z-10"
    style={{ height: '35%', bottom: -6 }}
  ></span>
</span>

                   
            <span className="relative mx-2 inline-block">
            products, 
            <span
    className="absolute left-0 w-full  bg-[#ec92e0] bg-opacity-30 -z-10"
    style={{ height: '35%', bottom: -6 }}
  ></span>
</span>
<span className="relative mx-2 inline-block">
driving businesses 

            <span
    className="absolute left-0 w-full  bg-[#ec92e0] bg-opacity-30 -z-10"
    style={{ height: '35%', bottom: -6 }}
  ></span>
</span>
          {' '}achieve global reach.
        </div>
</div>
<div></div>
<div></div>

    </div>
  )
}

export default About2