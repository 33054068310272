import React from 'react';
import about from '../assets/About me Banner.png'
import about2 from '../assets/smbanner.png'

const AboutHero = () => {
  return (
    <div className="w-full relative h-screen overflow-hidden"  style={{
      background: "linear-gradient(to right, rgba(223, 188, 237, 1), rgba(188, 191, 237, 0.8),rgba(188, 191, 237, 0.3))",
    }}>
      {/* Background Image */}
      <div className="absolute hidden md:flex -z-10 top-4 left-0 w-full h-full">
        <img
          src={about}
          className="h-full w-full object-center inset-0 scale-105 object-cover"
          alt="Background"
        />
      </div>
      <div className="absolute md:hidden flex -z-10 top-4 left-0 w-full h-full">
        <img
          src={about2}
          className="h-full w-full object-center inset-0 scale-105 object-cover"
          alt="Background"
        />
      </div>

      
      {/* <video
        id="video1"
        autoPlay
        loop
        muted
        src={Video}
        type="video/mp4"  
        className="object-cover -z-20  w-full h-full absolute"  
      /> */}

      {/* Content */}
      <div className="w-full flex justify-center h-full items-start flex-col px-6 sm:px-10 md:px-20 lg:px-40 xl:px-60">
        <div className="text-zinc-700 flex justify-center pb-16 sm:pb-20 md:pb-32 lg:pb-40 w-full lg:w-8/12 xl:w-6/12 items-center flex-col">
          <span className="text-4xl sm:text-5xl md:text-6xl pl-16 sm:pl-28 md:pl-28 lg:pl-24 w-full text-left font-thin font-dancing">
            Hey there
          </span>
          <span className="text-5xl sm:text-6xl md:text-7xl w-full text-center font-medium tracking-tight Playfair">
            I'm Aish!
          </span>
          <span className="text-xl sm:text-2xl md:text-3xl font-pop mt-6 sm:mt-8 md:mt-10 w-full text-center font-semibold">
            I help innovators and businesses thrive with AI-driven solutions and
            emerging technologies.
          </span>
        </div>
      </div>
    </div>
  );
};

export default AboutHero;
